import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  Layout,
  Target,
  MessageSquare,
  Star,
  Briefcase,
  Package,
  DollarSign,
  ChevronDown,
  CheckCircle,
  Search,
  Globe,
  Users,
  BarChart,
  Clock,
  Shield,
  Award
} from 'lucide-react';

const ServicePageCreatorLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const servicePageExamples = [
    {
      title: "Professional Services",
      preview: "We specialize in delivering exceptional consulting services that drive business growth and innovation. Our team of experts brings years of industry experience.",
      optimized: "Transform your business with our expert consulting services. Our proven strategies deliver real results, helping you grow faster and innovate smarter."
    },
    {
      title: "Digital Solutions",
      preview: "Our comprehensive digital solutions encompass website development, mobile applications, and cloud infrastructure implementation.",
      optimized: "Get a powerful online presence with our digital solutions. From stunning websites to custom apps, we build tools that grow your business."
    },
    {
      title: "Creative Services",
      preview: "We offer innovative design solutions and creative services tailored to elevate your brand identity and visual communication.",
      optimized: "Stand out with eye-catching designs that tell your story. Our creative team turns your vision into powerful visual messages."
    }
  ];

  const features = [
    {
      title: "AI-Powered Writing",
      description: "Create compelling service descriptions instantly",
      icon: Layout
    },
    {
      title: "Industry-Specific Templates",
      description: "Pre-built templates for your business sector",
      icon: Package
    },
    {
      title: "SEO Optimization",
      description: "Built-in SEO best practices and keywords",
      icon: Search
    },
    {
      title: "Conversion Focus",
      description: "Persuasive calls-to-action that convert",
      icon: Target
    },
    {
      title: "Brand Voice Control",
      description: "Maintain consistent tone and messaging",
      icon: MessageSquare
    },
    {
      title: "Value Proposition Builder",
      description: "Highlight your unique selling points",
      icon: Star
    }
  ];

  const benefits = [
    {
      name: "Time Savings",
      description: "Create service pages in minutes, not hours",
      icon: Clock
    },
    {
      name: "Higher Conversions",
      description: "Optimized content that drives sales",
      icon: BarChart
    },
    {
      name: "Professional Quality",
      description: "Expert-level writing for every industry",
      icon: Award
    },
    {
      name: "Global Reach",
      description: "Multilingual support for worldwide impact",
      icon: Globe
    }
  ];

  const industries = [
    {
      title: "Consultants",
      description: "Create professional service offerings that showcase expertise",
      results: ["45% increase in leads", "30% higher engagement"]
    },
    {
      title: "Digital Agencies",
      description: "Highlight your digital services with compelling copy",
      results: ["60% faster page creation", "40% better conversion"]
    },
    {
      title: "Professional Services",
      description: "Build trust with clear, professional service descriptions",
      results: ["50% more inquiries", "35% higher client quality"]
    }
  ];

  const faqs = [
    {
      question: "How does the Service Page Creator work?",
      answer: "Our AI analyzes your industry, target audience, and service details to generate professional, conversion-optimized service pages. Simply input your information, choose a template, and customize the generated content."
    },
    {
      question: "Can I customize the templates?",
      answer: "Yes! All templates are fully customizable. Edit the content, structure, and styling to match your brand perfectly while maintaining the optimized conversion elements."
    },
    {
      question: "Is the content SEO-friendly?",
      answer: "Absolutely. Every service page is created with built-in SEO best practices, including optimal keyword placement, meta descriptions, and header structure."
    },
    {
      question: "How many service pages can I create?",
      answer: "Depending on your subscription plan, you can create unlimited service pages. Each page can be edited and updated anytime to keep your services current."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Create Converting</span>
          <span className="block text-purple-600">Service Pages in Minutes</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Transform your services into compelling web pages that attract clients and drive conversions with AI-powered content creation.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Create Your Service Page
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#demo"
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            View Examples
          </a>
        </div>
      </div>

      {/* Interactive Template Showcase */}
      <div id="demo" className="max-w-6xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Transform Your Services</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Standard Copy</h3>
              <span className="text-orange-500 text-sm">Before</span>
            </div>
            <p className="text-gray-600 mb-4">{servicePageExamples[selectedTemplate].preview}</p>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-orange-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Conversion Rate</span>
                <span className="text-xl font-bold text-orange-600">2.1%</span>
              </div>
              <div className="bg-orange-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Engagement</span>
                <span className="text-lg font-bold text-orange-600">Low</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">AI-Optimized</h3>
              <span className="text-green-500 text-sm">After</span>
            </div>
            <p className="text-gray-600 mb-4">{servicePageExamples[selectedTemplate].optimized}</p>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-green-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Conversion Rate</span>
                <span className="text-xl font-bold text-green-600">8.4%</span>
              </div>
              <div className="bg-green-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Engagement</span>
                <span className="text-lg font-bold text-green-600">High</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-4 mt-8">
          {servicePageExamples.map((example, index) => (
            <button
              key={index}
              onClick={() => setSelectedTemplate(index)}
              className={`px-4 py-2 rounded-lg transition-all ${
                selectedTemplate === index
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {example.title}
            </button>
          ))}
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Powerful Features</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <feature.icon className="h-12 w-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Benefits Section */}
      <div className="bg-gradient-to-r from-purple-100 to-pink-50 py-16">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Our Service Page Creator</h2>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="space-y-6">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <benefit.icon className="h-8 w-8 text-purple-600" />
                  <div>
                    <h3 className="font-semibold">{benefit.name}</h3>
                    <p className="text-gray-600 text-sm">{benefit.description}</p>
                  </div>
                  <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-purple-600 rounded-full"
                      style={{ width: `${Math.random() * 40 + 60}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Industries Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Perfect For Every Industry</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {industries.map((industry, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                <h3 className="text-xl font-semibold mb-4">{industry.title}</h3>
                <p className="text-gray-600 mb-6">{industry.description}</p>
                <ul className="space-y-2">
                  {industry.results.map((result, i) => (
                    <li key={i} className="flex items-center text-green-600">
                      <CheckCircle className="h-5 w-5 mr-2" />
                      {result}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="bg-white py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-pink-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Create Converting Service Pages?</h2>
          <p className="text-xl mb-8">Join thousands of businesses showcasing their services effectively</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Get Started Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServicePageCreatorLanding;