import React, { useState } from 'react';
import { Wand2, Settings, FileText } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  ArrowUpRight, 
  Star, 
  Triangle, 
  Check, 
  ChevronDown,
  Shield,
  Zap,
  Globe,
  Users,
  MessageCircle,
  Image,
  ArrowRight
} from 'lucide-react';

const Home = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [selectedFaqCategory, setSelectedFaqCategory] = useState('content');
  const [expandedFaq, setExpandedFaq] = useState(null);

  const handlePlanSelection = (plan) => {
    // Store plan details in localStorage
    localStorage.setItem('selectedPlan', JSON.stringify({
      name: plan.name,
      price: selectedPlan === 'yearly' ? plan.price.yearlyMonthly : plan.price.monthly,
      billingCycle: selectedPlan,
      features: plan.features
    }));

    // Redirect to registration
    navigate('/register');
  };

  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  // Features Array
  const features = [
    {
      title: "AI Content Detection Proof",
      description: "Generate content that passes AI detection tools while maintaining natural language flow.",
      icon: <Shield className="text-blue-600 h-6 w-6" />,
      bgColor: "bg-blue-100"
    },
    {
      title: "Lightning Fast Generation",
      description: "Create high-quality content in seconds with our advanced AI technology.",
      icon: <Zap className="text-purple-600 h-6 w-6" />,
      bgColor: "bg-purple-100"
    },
    {
      title: "SEO Optimized",
      description: "Automatically optimize your content for search engines to improve rankings.",
      icon: <Globe className="text-indigo-600 h-6 w-6" />,
      bgColor: "bg-indigo-100"
    },
    {
      title: "Multiple Languages",
      description: "Create content in various languages to reach a global audience.",
      icon: <MessageCircle className="text-green-600 h-6 w-6" />,
      bgColor: "bg-green-100"
    },
    {
      title: "Team Collaboration",
      description: "Work together with your team to create and manage content efficiently.",
      icon: <Users className="text-orange-600 h-6 w-6" />,
      bgColor: "bg-orange-100"
    },
    {
      title: "AI Image Generation",
      description: "Generate unique images to complement your content using AI.",
      icon: <Image className="text-pink-600 h-6 w-6" />,
      bgColor: "bg-pink-100"
    }
  ];

  // Pricing Plans Array
  const pricingPlans = [
    {
      name: "Free",
      label: '',
      price: { 
        monthly: 0, 
        yearly: 0,
        yearlyMonthly: 0
      },
      features: [
        "Single Device Access",
        "1,000 Words/mo",
        "Limited Templates",
        "Basic Support"
      ],
      featured: false
    },
    {
      name: "Starter",
      label: '',
      price: { 
        monthly: 19,
        yearly: 180,
        yearlyMonthly: 15
      },
      features: [
        "Single Device Access",
        "50,000 Words/mo",
        "Limited Chat Words",
        "AI Image Creation - 50/mo",
        "Standard Quality Images",
        "200 Stock Images",
        "100+ Content Templates",
        "15+ Tones",
        "15+ Languages"
      ],
      featured: false
    },
    {
      name: "Pro",
      label: 'Most Popular',
      price: { 
        monthly: 79,
        yearly: 756,
        yearlyMonthly: 63
      },
      features: [
        "Multiple Device Access",
        "200,000 Words/mo",
        "Unlimited Chat Words",
        "AI Image Creation - 100/mo",
        "HD Quality Images",
        "500 Stock Images",
        "200+ Content Templates",
        "25+ Tones",
        "30+ Languages",
        "Priority Support"
      ],
      featured: true
    }
  ];

  // FAQ Categories Array
  const faqCategories = [
    { id: 'content', name: 'Content' },
    { id: 'billing', name: 'Billing' },
    { id: 'plans', name: 'Plans' },
    { id: 'dataProtection', name: 'Data Protection' },
    { id: 'refund', name: 'Refund / Cancellation' }
  ];

  // FAQ Data Object
  const faqs = {
    content: [
      {
        question: "What customization options does The Content GPT offer?",
        answer: "Our platform offers extensive customization including tone selection, writing style, content length, and formatting options to ensure your content matches your brand voice perfectly."
      },
      {
        question: "Can The Content GPT help improve my SEO efforts?",
        answer: "Yes, our platform analyzes keywords and competitor content to generate SEO-optimized articles that help improve your search rankings."
      },
      {
        question: "What types of content can I create with The Content GPT?",
        answer: "You can create blog posts, articles, social media content, product descriptions, email newsletters, landing page copy, and more with our versatile platform."
      }
    ],
    billing: [
      {
        question: "What payment methods do you accept?",
        answer: "We accept all major credit cards, PayPal, and bank transfers for business accounts. All payments are processed securely through our payment partners."
      },
      {
        question: "How does the billing cycle work?",
        answer: "Billing occurs monthly or annually, depending on your chosen plan. You can switch plans at any time, and we'll prorate any remaining balance."
      }
    ],
    plans: [
      {
        question: "Can I switch between plans?",
        answer: "Yes, you can upgrade or downgrade your plan at any time. Changes take effect immediately, and any remaining balance will be prorated."
      },
      {
        question: "What happens if I exceed my word limit?",
        answer: "You'll receive a notification when you reach 80% of your monthly word limit. You can either upgrade your plan or purchase additional words as needed, ensuring uninterrupted content creation."
      }
    ],
    dataProtection: [
      {
        question: "How do you protect my content and data?",
        answer: "We use enterprise-grade encryption and follow strict data protection protocols to ensure your content remains secure and private. All data is stored in SOC 2 compliant servers with regular security audits."
      },
      {
        question: "Who owns the content created with The Content GPT?",
        answer: "You retain 100% ownership of all content created using our platform. We never store, share, or reuse your generated content for any purpose."
      }
    ],
    refund: [
      {
        question: "What is your refund policy?",
        answer: "We offer a 14-day money-back guarantee if you're not satisfied with our service. No questions asked - simply contact our support team to process your refund."
      },
      {
        question: "How do I cancel my subscription?",
        answer: "You can cancel your subscription at any time from your account settings. Your access will continue until the end of your current billing period with no additional charges."
      }
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-50 to-white">
      {/* Hero Section */}
      <div className="relative max-w-7xl mx-auto px-4 pt-20 pb-16">
        <div className="absolute top-20 right-10">
          <Star className="text-purple-500 h-8 w-8 animate-pulse" />
        </div>
        <div className="absolute bottom-20 left-10">
          <Triangle className="text-blue-500 h-8 w-8 animate-bounce" />
        </div>

        <div className="text-center">
          <h1 className="text-5xl md:text-6xl font-bold mb-6">
            <span className="block text-gray-900">Create Better Content</span>
            <span className="block bg-gradient-to-r from-purple-600 to-blue-600 text-transparent bg-clip-text">
              in Less Time
            </span>
          </h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto mb-8">
            Transform your AI-generated content into natural, engaging writing that ranks #1 on search engines.
          </p>
          <div className="flex gap-4 justify-center">
            <Link 
              to="/register" 
              className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transition-all transform hover:scale-105"
            >
              Start Right Now
            </Link>
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('how-it-works')?.scrollIntoView({ behavior: 'smooth' });
              }}
              className="border border-blue-600 text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-all"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
      
      {/* Features Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">
            Everything You Need to Create Amazing Content
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-xl hover:shadow-lg transition-shadow">
                <div className={`h-12 w-12 ${feature.bgColor} rounded-lg mb-4 flex items-center justify-center`}>
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

 {/* How It Works Section */}
 <div id="how-it-works" className="bg-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900">How It Works</h2>
            <p className="mt-4 text-xl text-gray-600">Create amazing content in just four simple steps</p>
          </div>

    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
      {[
        {
          number: "01",
          title: "Choose Your Content Type",
          description: "Select from a variety of content types including blog posts, social media updates, or marketing copy.",
          icon: <Wand2 className="h-6 w-6 text-purple-600" />
        },
        {
          number: "02",
          title: "Customize Your Requirements",
          description: "Specify your preferences, tone of voice, and target audience to get perfectly tailored content.",
          icon: <Settings className="h-6 w-6 text-blue-600" />
        },
        {
          number: "03",
          title: "Generate Content",
          description: "Our AI instantly creates high-quality, engaging content that passes all AI detection tools.",
          icon: <Zap className="h-6 w-6 text-green-600" />
        },
        {
          number: "04",
          title: "Edit & Export",
          description: "Review your content, make any needed adjustments, and export it in your preferred format.",
          icon: <FileText className="h-6 w-6 text-orange-600" />
        }
      ].map((step, index, array) => (
        <div key={index} className="relative">
          {/* Connector Line */}
          {index < array.length - 1 && (
            <div className="hidden lg:block absolute top-1/4 left-full w-full h-px bg-gray-200 transform -translate-y-1/2" />
          )}
          
          {/* Step Card */}
          <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-shadow relative z-10">
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm font-bold text-purple-600">{step.number}</span>
              <div className="w-12 h-12 rounded-lg bg-gray-50 flex items-center justify-center">
                {step.icon}
              </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">{step.title}</h3>
            <p className="text-gray-600">{step.description}</p>
          </div>
        </div>
      ))}
    </div>

    
  </div>
</div>

      {/* Pricing Section */}
      <div className="bg-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-8">Simple, Transparent Pricing</h2>
          <div className="flex justify-center mb-12">
            <div className="bg-white rounded-lg p-1 shadow-sm inline-flex">
              <button 
                className={`px-6 py-2 rounded-md transition-colors ${
                  selectedPlan === 'monthly' ? 'bg-blue-600 text-white' : 'text-gray-600'
                }`}
                onClick={() => setSelectedPlan('monthly')}
              >
                Monthly
              </button>
              <button 
                className={`px-6 py-2 rounded-md flex items-center space-x-2 transition-colors ${
                  selectedPlan === 'yearly' ? 'bg-blue-600 text-white' : 'text-gray-600'
                }`}
                onClick={() => setSelectedPlan('yearly')}
              >
                <span>Yearly</span>
                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  20% OFF
                </span>
              </button>
            </div>
          </div>

          {/* Pricing Cards */}
          <div className="grid md:grid-cols-3 gap-8">
            {pricingPlans.map((plan, index) => (
              <div 
                key={index} 
                className={`bg-white rounded-xl p-8 ${
                  plan.featured ? 'border-2 border-blue-600 shadow-xl' : 'border border-gray-200'
                }`}
              >
                {plan.label && (
                  <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                    <span className="bg-blue-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                      {plan.label}
                    </span>
                  </div>
                )}
                <h3 className="text-2xl font-bold mb-4">{plan.name}</h3>
                <div className="mb-6">
                  <span className="text-4xl font-bold">
                    ${selectedPlan === 'yearly' ? plan.price.yearlyMonthly : plan.price.monthly}
                  </span>
                  <span className="text-gray-600 ml-2">/month</span>
                  {selectedPlan === 'yearly' && plan.price.yearlyMonthly > 0 && (
                    <div className="mt-1 flex items-center justify-center">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Save 20%
                      </span>
                    </div>
                  )}
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center gap-3">
                      <Check className="text-blue-600 h-5 w-5" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handlePlanSelection(plan)}
                  className={`w-full py-3 rounded-lg font-medium transition-all flex items-center justify-center ${
                    plan.featured 
                      ? 'bg-blue-600 text-white hover:bg-blue-700' 
                      : 'border border-blue-600 text-blue-600 hover:bg-blue-50'
                  }`}
                >
                  <span>Get Started</span>
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-white py-24">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="bg-white rounded-xl shadow-lg">
            <div className="flex border-b overflow-x-auto">
              {faqCategories.map((category, index) => (
                <button
                  key={index}
                  className={`flex-1 px-6 py-4 text-center whitespace-nowrap ${selectedFaqCategory === category.id ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'}`}
                  onClick={() => setSelectedFaqCategory(category.id)}
                >
                  {category.name}
                </button>
              ))}
            </div>
            <div className="p-6">
              {faqs[selectedFaqCategory].map((faq, index) => (
                <div key={index} className="mb-4 last:mb-0">
                  <button 
                    className="flex justify-between items-center w-full text-left p-4 bg-gray-50 rounded-lg hover:bg-gray-100"
                    onClick={() => toggleFaq(index)}
                  >
                    <span className="font-medium">{faq.question}</span>
                    <ChevronDown className={`h-5 w-5 transform transition-transform ${expandedFaq === index ? 'rotate-180' : ''}`} />
                  </button>
                  {expandedFaq === index && (
                    <div className="p-4 bg-white border-t">
                      <p className="text-gray-600">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Data
const features = [
  {
    title: "AI Content Detection Proof",
    description: "Generate content that passes AI detection tools while maintaining natural language flow.",
    icon: <Shield className="text-blue-600 h-6 w-6" />,
    bgColor: "bg-blue-100"
  },
  {
    title: "Lightning Fast Generation",
    description: "Create high-quality content in seconds with our advanced AI technology.",
    icon: <Zap className="text-purple-600 h-6 w-6" />,
    bgColor: "bg-purple-100"
  },
  {
    title: "SEO Optimized",
    description: "Automatically optimize your content for search engines to improve rankings.",
    icon: <Globe className="text-indigo-600 h-6 w-6" />,
    bgColor: "bg-indigo-100"
  },
  {
    title: "Multiple Languages",
    description: "Create content in various languages to reach a global audience.",
    icon: <MessageCircle className="text-green-600 h-6 w-6" />,
    bgColor: "bg-green-100"
  },
  {
    title: "Team Collaboration",
    description: "Work together with your team to create and manage content efficiently.",
    icon: <Users className="text-orange-600 h-6 w-6" />,
    bgColor: "bg-orange-100"
  },
  {
    title: "AI Image Generation",
    description: "Generate unique images to complement your content using AI.",
    icon: <Image className="text-pink-600 h-6 w-6" />,
    bgColor: "bg-pink-100"
  }
];

const pricingPlans = [
  {
    name: "Free",
    label: '',
    price: { 
      monthly: 0, 
      yearly: 0,
      yearlyMonthly: 0
    },
    features: [
      "Single Device Access",
      "1,000 Words/mo",
      "Limited Templates",
      "Basic Support"
    ],
    featured: false
  },
  {
    name: "Starter",
    label: '',
    price: { 
      monthly: 19,
      yearly: 180,
      yearlyMonthly: 15  // 19 with 20% discount
    },
    features: [
      "Single Device Access",
      "50,000 Words/mo",
      "Limited Chat Words",
      "AI Image Creation - 50/mo",
      "Standard Quality Images",
      "200 Stock Images",
      "100+ Content Templates",
      "15+ Tones",
      "15+ Languages"
    ],
    featured: false
  },
  {
    name: "Pro",
    label: 'Most Popular',
    price: { 
      monthly: 79,
      yearly: 756,
      yearlyMonthly: 63  // 79 with 20% discount
    },
    features: [
      "Multiple Device Access",
      "200,000 Words/mo",
      "Unlimited Chat Words",
      "AI Image Creation - 100/mo",
      "HD Quality Images",
      "500 Stock Images",
      "200+ Content Templates",
      "25+ Tones",
      "30+ Languages",
      "Priority Support"
    ],
    featured: true
  }
];

const faqCategories = [
  { id: 'content', name: 'Content' },
  { id: 'billing', name: 'Billing' },
  { id: 'plans', name: 'Plans' },
  { id: 'dataProtection', name: 'Data Protection' },
  { id: 'refund', name: 'Refund / Cancellation' }
];

const faqs = {
  content: [
    {
      question: "What customization options does The Content GPT offer?",
      answer: "Our platform offers extensive customization including tone selection, writing style, content length, and formatting options to ensure your content matches your brand voice perfectly."
    },
    {
      question: "Can The Content GPT help improve my SEO efforts?",
      answer: "Yes, our platform analyzes keywords and competitor content to generate SEO-optimized articles that help improve your search rankings."
    },
    {
      question: "What types of content can I create with The Content GPT?",
      answer: "You can create blog posts, articles, social media content, product descriptions, email newsletters, landing page copy, and more with our versatile platform."
    }
  ],
  billing: [
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards, PayPal, and bank transfers for business accounts. All payments are processed securely through our payment partners."
    },
    {
      question: "How does the billing cycle work?",
      answer: "Billing occurs monthly or annually, depending on your chosen plan. You can switch plans at any time, and we'll prorate any remaining balance."
    }
  ],
  plans: [
    {
      question: "Can I switch between plans?",
      answer: "Yes, you can upgrade or downgrade your plan at any time. Changes take effect immediately, and any remaining balance will be prorated."
    },
    {
      question: "What happens if I exceed my word limit?",
      answer: "You'll receive a notification when you reach 80% of your monthly word limit. You can either upgrade your plan or purchase additional words as needed, ensuring uninterrupted content creation."
    }
  ],
  dataProtection: [
    {
      question: "How do you protect my content and data?",
      answer: "We use enterprise-grade encryption and follow strict data protection protocols to ensure your content remains secure and private. All data is stored in SOC 2 compliant servers with regular security audits."
    },
    {
      question: "Who owns the content created with The Content GPT?",
      answer: "You retain 100% ownership of all content created using our platform. We never store, share, or reuse your generated content for any purpose."
    }
  ],
  refund: [
    {
      question: "What is your refund policy?",
      answer: "We offer a 14-day money-back guarantee if you're not satisfied with our service. No questions asked - simply contact our support team to process your refund."
    },
    {
      question: "How do I cancel my subscription?",
      answer: "You can cancel your subscription at any time from your account settings. Your access will continue until the end of your current billing period with no additional charges."
    }
  ]
};

export default Home;