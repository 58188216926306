// src/config/security.js

const getCSPMeta = () => {
  const apiDomains = [
    "'self'",
    process.env.REACT_APP_API_URL,
    'https://api.thecontentgpt.com',
    'https://*.paddle.com',
    'https://thecontentgpt.onrender.com',
    'https://thecontentgpt-api.onrender.com',
    process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : null,
    process.env.NODE_ENV === 'development' ? 'ws://localhost:3000' : null
  ].filter(Boolean);

  return {
    'default-src': ["'self'"],
    'script-src': [
      "'self'",
      "'unsafe-inline'",
      "'unsafe-eval'",
      'https://*.paddle.com'
    ],
    'style-src': ["'self'", "'unsafe-inline'"],
    'img-src': ["'self'", "data:", "https:", "https://*.paddle.com"],
    'connect-src': apiDomains,
    'font-src': ["'self'", "data:"],
    'frame-src': ["'self'", "https://*.paddle.com"],
    'worker-src': ["'self'", "blob:"],
    'manifest-src': ["'self'"],
    'media-src': ["'self'", "blob:"]
  };
};

export default getCSPMeta;