import React, { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { 
  BarChart as BarChartIcon,
  TrendingUp,
  Users,
  Clock,
  Calendar,
  ChevronDown,
  ArrowUp,
  ArrowDown,
  Filter,
  Download,
  X
} from 'lucide-react';

import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const Analytics = () => {
  const [timeRange, setTimeRange] = useState('7d');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [usageData, setUsageData] = useState([]);
  const [dashboardStats, setDashboardStats] = useState({});
  const [featureUsage, setFeatureUsage] = useState({});
  const [showContentFilter, setShowContentFilter] = useState(false);
  const [showFeatureFilter, setShowFeatureFilter] = useState(false);
  const [contentMetrics, setContentMetrics] = useState(['words', 'points']);
  const [featureMetrics, setFeatureMetrics] = useState(['count', 'points']);
  const [analyticsData, setAnalyticsData] = useState({
    chartData: [],
    featureUsage: {},
    summary: {
      totalWordsGenerated: 0,
      totalPointsUsed: 0,
      averageWordsPerDay: 0
    }
  });

  // Only update the fetchAnalytics function
  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const response = await apiService.fetch(`/dashboard/analytics?timeRange=${timeRange}`);
        
        // Format chart data
        const formattedData = response.chartData.map(day => ({
          date: new Date(day.date).toLocaleDateString('en-US', { weekday: 'short' }),
          words: day.words,
          points: day.points
        }));

        setUsageData(formattedData);
        setDashboardStats(response.summary);
        setFeatureUsage(response.featureUsage);
        setAnalyticsData(response);
        setLoading(false);
      } catch (error) {
        console.error('Analytics error:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [timeRange]);

  // Filter Popup Component
  const FilterPopup = ({ title, metrics, selectedMetrics, onMetricToggle, onClose }) => (
    <div className="absolute top-full right-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 p-4 z-10 w-64">
      <div className="flex justify-between items-center mb-3">
        <h4 className="font-medium text-gray-900 dark:text-white">{title}</h4>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
          <X className="h-4 w-4" />
        </button>
      </div>
      <div className="space-y-2">
        {metrics.map((metric) => (
          <label key={metric} className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={selectedMetrics.includes(metric)}
              onChange={() => onMetricToggle(metric)}
              className="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
            />
            <span className="text-sm text-gray-700 dark:text-gray-300 capitalize">
              {metric === 'count' ? 'Usage Count' : metric}
            </span>
          </label>
        ))}
      </div>
    </div>
  );

  // Export data function
  const exportData = () => {
    const data = {
      summary: analyticsData.summary,
      timeRange,
      chartData: analyticsData.chartData,
      featureUsage: analyticsData.featureUsage
    };

    // Create CSV content
    let csv = 'Analytics Export\n\n';
    
    // Add summary
    csv += 'Summary\n';
    Object.entries(data.summary).forEach(([key, value]) => {
      csv += `${key},${value}\n`;
    });
    
    csv += '\nDaily Data\n';
    csv += 'Date,Words Generated,Points Used\n';
    data.chartData.forEach(day => {
      csv += `${day.date},${day.words},${day.points}\n`;
    });
    
    csv += '\nFeature Usage\n';
    csv += 'Feature,Usage Count,Points Used\n';
    Object.entries(data.featureUsage).forEach(([feature, usage]) => {
      csv += `${feature},${usage.count},${usage.points}\n`;
    });

    // Create and trigger download
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `analytics-export-${timeRange}-${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const performanceStats = [
    {
      title: "Total Words Generated",
      value: analyticsData.summary.totalWordsGenerated.toLocaleString(),
      change: "+12.5%",
      trend: "up",
      period: "vs last period"
    },
    {
      title: "Average Words per Day",
      value: analyticsData.summary.averageWordsPerDay.toLocaleString(),
      change: "+0.5",
      trend: "up",
      period: "vs last period"
    },
    {
      title: "Total Points Used",
      value: analyticsData.summary.totalPointsUsed.toLocaleString(),
      change: "-5.2%",
      trend: "down",
      period: "vs last period"
    },
    {
      title: "Features Used",
      value: Object.keys(analyticsData.featureUsage).length.toString(),
      change: "+8.3%",
      trend: "up",
      period: "vs last period"
    }
  ];

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-red-600 dark:text-red-400">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Analytics Dashboard</h1>
            <p className="text-gray-600 dark:text-gray-400 mt-1">Track your content performance and insights</p>
          </div>
          <div className="flex gap-4">
            <select
              className="px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent text-gray-900 dark:text-white"
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
            >
              <option value="7d">Last 7 days</option>
              <option value="30d">Last 30 days</option>
              <option value="90d">Last 90 days</option>
              <option value="1y">Last year</option>
            </select>
            <button 
              onClick={exportData}
              className="flex items-center px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 text-gray-900 dark:text-white"
            >
              <Download className="h-5 w-5 mr-2" />
              Export
            </button>
          </div>
        </div>

        {/* Performance Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {performanceStats.map((stat, index) => (
            <div key={index} className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">{stat.title}</h3>
              <div className="flex items-end justify-between">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">{stat.value}</p>
                <div className={`flex items-center ${
                  stat.trend === 'up' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
                }`}>
                  {stat.trend === 'up' ? (
                    <ArrowUp className="h-4 w-4 mr-1" />
                  ) : (
                    <ArrowDown className="h-4 w-4 mr-1" />
                  )}
                  <span className="text-sm font-medium">{stat.change}</span>
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">{stat.period}</p>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
          {/* Usage Chart */}
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <div className="flex justify-between items-center mb-6 relative">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Content Performance</h3>
              <button 
                onClick={() => setShowContentFilter(!showContentFilter)}
                className="flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
              >
                <Filter className="h-4 w-4 mr-2" />
                Filter
              </button>
              
              {showContentFilter && (
                <FilterPopup
                  title="Content Metrics"
                  metrics={['words', 'points']}
                  selectedMetrics={contentMetrics}
                  onMetricToggle={(metric) => {
                    if (contentMetrics.includes(metric)) {
                      setContentMetrics(contentMetrics.filter(m => m !== metric));
                    } else {
                      setContentMetrics([...contentMetrics, metric]);
                    }
                  }}
                  onClose={() => setShowContentFilter(false)}
                />
              )}
            </div>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={analyticsData.chartData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis 
                    dataKey="date" 
                    stroke="#9CA3AF"
                    style={{ fontSize: '12px' }}
                  />
                  <YAxis
                    stroke="#9CA3AF"
                    style={{ fontSize: '12px' }}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: 'none',
                      borderRadius: '8px',
                      color: '#fff'
                    }}
                  />
                  <Legend />
                  {contentMetrics.includes('words') && (
                    <Line 
                      type="monotone" 
                      dataKey="words" 
                      stroke="#8B5CF6" 
                      name="Words Generated"
                    />
                  )}
                  {contentMetrics.includes('points') && (
                    <Line 
                      type="monotone" 
                      dataKey="points" 
                      stroke="#3B82F6" 
                      name="Points Used"
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Feature Usage Chart */}
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
            <div className="flex justify-between items-center mb-6 relative">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Feature Usage</h3>
              <button 
                onClick={() => setShowFeatureFilter(!showFeatureFilter)}
                className="flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
              >
                <Filter className="h-4 w-4 mr-2" />
                Filter
              </button>

              {showFeatureFilter && (
                <FilterPopup
                  title="Feature Metrics"
                  metrics={['count', 'points']}
                  selectedMetrics={featureMetrics}
                  onMetricToggle={(metric) => {
                    if (featureMetrics.includes(metric)) {
                      setFeatureMetrics(featureMetrics.filter(m => m !== metric));
                    } else {
                      setFeatureMetrics([...featureMetrics, metric]);
                    }
                  }}
                  onClose={() => setShowFeatureFilter(false)}
                />
              )}
            </div>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={Object.entries(analyticsData.featureUsage).map(([feature, data]) => ({
                  feature,
                  count: data.count,
                  points: data.points
                }))}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis 
                    dataKey="feature" 
                    stroke="#9CA3AF"
                    style={{ fontSize: '12px' }}
                  />
                  <YAxis
                    stroke="#9CA3AF"
                    style={{ fontSize: '12px' }}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: 'none',
                      borderRadius: '8px',
                      color: '#fff'
                    }}
                  />
                  <Legend />
                  {featureMetrics.includes('count') && (
                    <Bar dataKey="count" fill="#8B5CF6" name="Usage Count" />
                  )}
                  {featureMetrics.includes('points') && (
                    <Bar dataKey="points" fill="#3B82F6" name="Points Used" />
                  )}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Recent Activity</h3>
          </div>
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {analyticsData.chartData.slice(-5).map((activity, index) => (
              <div key={index} className="p-6">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      Content Generation
                    </h4>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                      Generated {activity.words.toLocaleString()} words
                    </p>
                  </div>
                  <div className="ml-6">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200">
                      {activity.points.toLocaleString()} points
                    </span>
                  </div>
                </div>
                <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  {new Date(activity.date).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;