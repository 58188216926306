import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  Wand2,
  Sparkles,
  RefreshCw,
  FileText,
  Languages,
  Target,
  Brain,
  Settings,
  Shuffle,
  Check,
  PenLine,
  MessageSquare,
  Lightbulb,
  Feather,
  Palette,
  Globe,
  ScrollText
} from 'lucide-react';

const ContentRewriterLanding = () => {
  const [selectedTone, setSelectedTone] = useState('professional');
  const [selectedStyle, setSelectedStyle] = useState(0);
  const [activeVariation, setActiveVariation] = useState(0);

  const scrollToDemo = (e) => {
    e.preventDefault();
    const demoSection = document.getElementById('demo');
    demoSection.scrollIntoView({ behavior: 'smooth' });
  };

  const toneExamples = {
    professional: {
      original: "This product is really good and helps you get things done faster.",
      rewritten: "This innovative solution optimizes workflow efficiency, delivering measurable productivity gains."
    },
    casual: {
      original: "The statistical analysis indicates a significant correlation between the variables.",
      rewritten: "We found that these things are definitely connected – and the numbers back it up!"
    },
    creative: {
      original: "The sunset occurred at 6:30 PM yesterday.",
      rewritten: "Golden rays painted the sky in brilliant hues as day surrendered to dusk at 6:30."
    }
  };

  const writingStyles = [
    {
      id: 0,
      name: "Original",
      text: "We provide solutions for businesses.",
      icon: FileText
    },
    {
      id: 1,
      name: "Variation 1",
      text: "We deliver innovative business solutions tailored to your needs.",
      icon: PenLine
    },
    {
      id: 2,
      name: "Variation 2",
      text: "Empowering businesses with strategic solutions for growth.",
      icon: Lightbulb
    },
    {
      id: 3,
      name: "Variation 3",
      text: "Transform your business with our comprehensive solutions.",
      icon: Wand2
    }
  ];

  const features = [
    {
      title: "AI-Powered Rewriting",
      description: "Advanced algorithms that understand context and meaning",
      icon: Brain,
      color: "blue"
    },
    {
      title: "Multiple Tone Options",
      description: "Adapt your content for any audience or platform",
      icon: MessageSquare,
      color: "purple"
    },
    {
      title: "Plagiarism-Free",
      description: "100% unique content while maintaining your message",
      icon: Check,
      color: "green"
    },
    {
      title: "Multilingual Support",
      description: "Rewrite content in multiple languages",
      icon: Globe,
      color: "indigo"
    }
  ];

  const toneOptions = [
    { name: "Professional", value: "professional" },
    { name: "Casual", value: "casual" },
    { name: "Creative", value: "creative" }
  ];

  const contentTypes = [
    {
      title: "Blog Posts",
      description: "Transform existing articles into fresh, engaging content",
      variations: 4
    },
    {
      title: "Marketing Copy",
      description: "Rewrite promotional material for better conversion",
      variations: 6
    },
    {
      title: "Social Media",
      description: "Create platform-specific variations of your message",
      variations: 8
    },
    {
      title: "Product Descriptions",
      description: "Generate unique descriptions for similar products",
      variations: 5
    }
  ];

  const benefits = [
    {
      title: "Save Time",
      description: "Generate multiple variations in seconds",
      icon: RefreshCw
    },
    {
      title: "Maintain Brand Voice",
      description: "Consistent tone across all content",
      icon: MessageSquare
    },
    {
      title: "SEO Friendly",
      description: "Optimized for search engines",
      icon: Target
    },
    {
      title: "Multiple Languages",
      description: "Rewrite in 50+ languages",
      icon: Languages
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <div className="flex justify-center mb-6">
          <Wand2 className="h-16 w-16 text-purple-600" />
        </div>
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Transform Your Content</span>
          <span className="block text-purple-600">Without Losing Its Essence</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Instantly rewrite any text into fresh, unique content while maintaining your message and tone.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Start Rewriting
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <Link 
            to="#demo"
            onClick={scrollToDemo}
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            View Examples
          </Link>
        </div>
      </div>

      {/* Interactive Tone Switcher */}
      <div id="demo" className="max-w-6xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">See How It Works</h2>
        <div className="flex justify-center gap-4 mb-8">
          {toneOptions.map((tone) => (
            <button
              key={tone.value}
              onClick={() => setSelectedTone(tone.value)}
              className={`px-6 py-3 rounded-lg transition-all ${
                selectedTone === tone.value
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {tone.name}
            </button>
          ))}
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Original Text</h3>
            <p className="text-gray-600 mb-4">{toneExamples[selectedTone].original}</p>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Rewritten Text</h3>
            <p className="text-gray-600 mb-4">{toneExamples[selectedTone].rewritten}</p>
          </div>
        </div>
      </div>

      {/* Style Variations */}
      <div className="bg-white py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Multiple Variations</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {writingStyles.map((style) => (
              <div
                key={style.id}
                onClick={() => setSelectedStyle(style.id)}
                className={`cursor-pointer p-6 rounded-xl transition-all ${
                  selectedStyle === style.id
                    ? 'bg-purple-100 border-2 border-purple-600'
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
              >
                <style.icon className="h-6 w-6 text-purple-600 mb-3" />
                <h3 className="font-semibold mb-2">{style.name}</h3>
                <p className="text-sm text-gray-600">{style.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Content Types Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Rewrite Any Content Type</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {contentTypes.map((type, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <h3 className="text-xl font-semibold mb-3">{type.title}</h3>
              <p className="text-gray-600 mb-4">{type.description}</p>
              <div className="flex items-center text-purple-600">
                <Sparkles className="h-5 w-5 mr-2" />
                <span>{type.variations} variations</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-gradient-to-r from-purple-100 to-pink-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Advanced Features</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                <feature.icon className={`h-12 w-12 text-${feature.color}-600 mb-4`} />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Benefits Grid */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Our Rewriter?</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="text-center">
                <div className="bg-purple-100 rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                  <benefit.icon className="h-8 w-8 text-purple-600" />
                </div>
                <h3 className="font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-pink-600 py-16">
        <div className="max-w-4xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Content?</h2>
          <p className="text-xl mb-8">Join thousands of writers creating unique content in seconds</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Rewriting Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContentRewriterLanding;