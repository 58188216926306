// Create a new file: src/utils/pricingUtils.js

export const PLAN_TYPES = {
    FREE: 'free',
    STARTER: 'starter',
    PRO: 'pro'
  };
  
  // Store selected plan in localStorage to access after registration
  export const setSelectedPlan = (plan) => {
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
  };
  
  // Get selected plan from localStorage
  export const getSelectedPlan = () => {
    const plan = localStorage.getItem('selectedPlan');
    return plan ? JSON.parse(plan) : null;
  };
  
  // Clear selected plan
  export const clearSelectedPlan = () => {
    localStorage.removeItem('selectedPlan');
  };
  
  // Handle plan selection
  export const handlePlanSelection = (plan, navigate) => {
    // Store selected plan details
    setSelectedPlan({
      type: plan.name.toLowerCase(),
      price: plan.price,
      period: plan.period
    });
    
    // Redirect to registration page
    navigate('/register');
  };