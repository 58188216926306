import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  PenTool,
  Sparkles,
  Target,
  BarChart,
  Share2,
  Layers,
  ArrowRight,
  Star,
  Zap,
  ChevronDown,
  Globe,
  MessageCircle,
  Copy,
  RefreshCcw
} from 'lucide-react';

const AdCopyGeneratorLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const features = [
    {
      title: "Multi-Platform Ready",
      description: "Generate copy for Facebook, Google, LinkedIn, and more",
      icon: Share2
    },
    {
      title: "Conversion Focused",
      description: "AI-powered copy that drives clicks and conversions",
      icon: Target
    },
    {
      title: "Performance Metrics",
      description: "Real-time suggestions for copy improvement",
      icon: BarChart
    },
    {
      title: "Multiple Variations",
      description: "Generate multiple versions for A/B testing",
      icon: Layers
    },
    {
      title: "Brand Voice Match",
      description: "Maintains your brand's unique tone and style",
      icon: MessageCircle
    },
    {
      title: "30+ Languages",
      description: "Create ad copy in multiple languages instantly",
      icon: Globe
    }
  ];

  const adTypes = [
    "Social Media Ads",
    "Google Ads",
    "Display Ads",
    "Product Descriptions",
    "Landing Page Copy",
    "Email Headlines",
    "Banner Ads",
    "Video Ad Scripts"
  ];

  const testimonials = [
    {
      name: "Sarah Martinez",
      role: "PPC Specialist",
      company: "Digital Agency",
      content: "This tool cut our ad copy creation time by 75%. The conversion rates on our ads have improved significantly."
    },
    {
      name: "James Kim",
      role: "Marketing Director",
      company: "E-commerce Store",
      content: "The variety of ad formats and instant translations helped us expand to international markets effortlessly."
    },
    {
      name: "Emma Watson",
      role: "Social Media Manager",
      company: "SaaS Company",
      content: "The AI understands different platform requirements and generates perfectly sized copy every time."
    }
  ];

  const faqs = [
    {
      question: "How does the AI generate relevant ad copy?",
      answer: "Our AI analyzes your product, target audience, and campaign objectives to create compelling ad copy. It's trained on millions of successful ads and follows platform-specific best practices."
    },
    {
      question: "Can I maintain brand consistency across different platforms?",
      answer: "Yes! The AI learns your brand voice and maintains consistency while adapting to each platform's unique requirements and audience expectations."
    },
    {
      question: "What ad platforms are supported?",
      answer: "We support all major advertising platforms including Facebook, Instagram, Google Ads, LinkedIn, Twitter, and more. The AI automatically formats copy to meet each platform's specifications."
    },
    {
      question: "How many variations can I generate?",
      answer: "You can generate unlimited variations for your ads. We recommend creating 3-5 versions for effective A/B testing and optimization."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">AI-Powered</span>
          <span className="block text-purple-600">Ad Copy Generator</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Create high-converting ad copy for any platform in seconds. Powered by AI that understands what makes ads perform.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Start Writing Ads
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#examples"
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            View Examples
          </a>
        </div>

        {/* Trust Indicators */}
        <div className="mt-12 flex justify-center items-center space-x-8">
          <div className="flex items-center">
            <Copy className="h-5 w-5 text-purple-500 mr-2" />
            <span>1M+ Ads Created</span>
          </div>
          <div className="flex items-center">
            <Zap className="h-5 w-5 text-purple-500 mr-2" />
            <span>30K+ Users</span>
          </div>
          <div className="flex items-center">
            <Star className="h-5 w-5 text-purple-500 mr-2" />
            <span>4.8/5 Rating</span>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Features That Drive Results</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <feature.icon className="h-12 w-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Ad Types Section */}
      <div id="examples" className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Create Any Type of Ad</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {adTypes.map((type, index) => (
              <div 
                key={index}
                className="bg-purple-50 p-6 rounded-xl text-center hover:bg-purple-100 transition-colors"
              >
                <PenTool className="h-8 w-8 text-purple-600 mx-auto mb-3" />
                <h3 className="font-medium">{type}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Process Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Create Converting Ads in 3 Steps</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">1</span>
              </div>
              <h3 className="font-semibold mb-2">Describe Your Product</h3>
              <p className="text-gray-600">Input your product details and target audience</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">2</span>
              </div>
              <h3 className="font-semibold mb-2">Generate Variations</h3>
              <p className="text-gray-600">Get multiple ad copy options instantly</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">3</span>
              </div>
              <h3 className="font-semibold mb-2">Export & Launch</h3>
              <p className="text-gray-600">Use directly in your ad campaigns</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Loved by Marketing Teams</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-purple-50 p-6 rounded-xl">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Create Converting Ad Copy?</h2>
          <p className="text-xl mb-8">Join thousands of marketers saving time with AI-powered ad creation</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Writing Ads
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdCopyGeneratorLanding;