import React, { createContext, useContext, useEffect, useState } from 'react';

const PaddleContext = createContext();

export const PaddleProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializePaddle = async () => {
      if (window.Paddle) {
        return;
      }

      try {
        const script = document.createElement('script');
        script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
        script.async = true;
        
        script.onload = async () => {
          try {
            await window.Paddle.Environment.set('sandbox');
            
            await window.Paddle.Setup({ 
              token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN
            });

            console.log('Paddle initialized in sandbox mode');
            setIsInitialized(true);
          } catch (error) {
            console.error('Paddle initialization error:', error);
            setError(error.message);
          }
        };

        document.body.appendChild(script);
      } catch (error) {
        console.error('Paddle setup error:', error);
        setError(error.message);
      }
    };

    initializePaddle();
  }, []);

  const openCheckout = async (priceId, options = {}) => {
    if (!isInitialized) {
      throw new Error('Paddle is not initialized');
    }
  
    try {
      console.log('Opening checkout with priceId:', priceId);
      
      return await window.Paddle.Checkout.open({
        items: [{
          priceId: priceId,
          quantity: 1
        }],
        customer: options.email ? {
          email: options.email
        } : undefined,
        customData: {
          userId: options.userId,
          planName: options.planName,
          billingCycle: options.billingCycle
        }
      });
    } catch (error) {
      console.error('Checkout error:', error);
      throw error;
    }
  };

  return (
    <PaddleContext.Provider value={{ 
      isInitialized,
      error,
      openCheckout
    }}>
      {children}
    </PaddleContext.Provider>
  );
};

export const usePaddle = () => {
  const context = useContext(PaddleContext);
  if (!context) {
    throw new Error('usePaddle must be used within a PaddleProvider');
  }
  return context;
};