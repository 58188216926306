import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  LayoutTemplate,
  Wand2,
  Target,
  ArrowRight,
  Star,
  Users,
  Blocks,
  Eye,
  Palette,
  Brain,
  ChevronDown,
  LineChart,
  Rocket,
  Code,
  Shuffle
} from 'lucide-react';

const LandingPageWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [activeTab, setActiveTab] = useState('conversion');

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const features = [
    {
      title: "AI Content Generation",
      description: "Create compelling headlines, CTAs, and body copy instantly",
      icon: Brain
    },
    {
      title: "Section Builder",
      description: "Pre-built high-converting section templates",
      icon: Blocks
    },
    {
      title: "SEO Optimization",
      description: "Built-in SEO best practices and recommendations",
      icon: Target
    },
    {
      title: "Visual Editor",
      description: "Drag-and-drop interface for easy customization",
      icon: Eye
    },
    {
      title: "Style Management",
      description: "Brand-consistent design systems and styling",
      icon: Palette
    },
    {
      title: "Code Export",
      description: "Clean, optimized code output in multiple frameworks",
      icon: Code
    }
  ];

  const conversionElements = [
    "Hero Sections",
    "Feature Blocks",
    "Testimonial Grids",
    "Pricing Tables",
    "CTA Sections",
    "Social Proof",
    "FAQ Accordions",
    "Contact Forms"
  ];

  const smartComponents = [
    "AI Content Generator",
    "Dynamic Forms",
    "A/B Test Variants",
    "Analytics Dashboard",
    "Heat Maps",
    "Conversion Tracker",
    "SEO Optimizer",
    "Mobile Preview"
  ];

  const testimonials = [
    {
      name: "Alex Rivera",
      role: "Conversion Optimizer",
      company: "Growth Co",
      content: "This tool has revolutionized our landing page creation process. We've seen a 50% increase in conversion rates across all our pages."
    },
    {
      name: "Michelle Patel",
      role: "SaaS Founder",
      company: "TechStart",
      content: "The AI-generated content combined with conversion-optimized layouts helped us launch our product 3x faster."
    },
    {
      name: "Tom Anderson",
      role: "Digital Marketing Lead",
      company: "WebAgency",
      content: "The variety of section templates and instant customization options save us hours on each client project."
    }
  ];

  const metrics = [
    {
      label: "Average Conversion Lift",
      value: "127%",
      description: "Compared to manually created pages"
    },
    {
      label: "Time Saved",
      value: "85%",
      description: "Reduction in page creation time"
    },
    {
      label: "Mobile Optimization",
      value: "100%",
      description: "Responsive on all devices"
    }
  ];

  const faqs = [
    {
      question: "How does the AI landing page writer work?",
      answer: "Our AI analyzes your product, target audience, and industry to generate optimized landing page content and structure. It combines proven conversion principles with your brand voice to create compelling pages that convert."
    },
    {
      question: "Can I edit the generated content and layout?",
      answer: "Yes! While our AI creates optimized initial versions, you have full control to edit, customize, and refine every element through our visual editor. You can also save your customizations as templates."
    },
    {
      question: "What formats can I export my landing page in?",
      answer: "We support exports to HTML/CSS, React, Vue.js, and WordPress formats. All exported code is clean, optimized, and follows modern development standards."
    },
    {
      question: "Do the landing pages work on mobile devices?",
      answer: "All generated pages are fully responsive and tested across devices. Our AI automatically applies mobile-first design principles and optimizations."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl font-bold mb-6">
            <span className="block">Create High-Converting</span>
            <span className="block text-green-600">Landing Pages with AI</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Transform your ideas into conversion-optimized landing pages in minutes. 
            Powered by AI that understands what makes visitors convert.
          </p>
          <div className="flex justify-center gap-4">
            <Link 
              to="/register"
              className="bg-green-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-green-700 transition-all flex items-center"
            >
              Start Building Free
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <a 
              href="#demo"
              className="border border-green-600 text-green-600 px-8 py-3 rounded-lg font-medium hover:bg-green-50 transition-all"
            >
              Watch Demo
            </a>
          </div>
        </div>

        {/* Live Counter Section */}
        <div className="mt-16 bg-white rounded-xl shadow-lg py-8 px-4 max-w-4xl mx-auto">
          <div className="grid grid-cols-3 gap-8">
            {metrics.map((metric, index) => (
              <div key={index} className="text-center">
                <div className="text-3xl font-bold text-green-600 mb-2">{metric.value}</div>
                <div className="font-medium mb-1">{metric.label}</div>
                <div className="text-sm text-gray-500">{metric.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Demo Section */}
      <div id="demo" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">See It In Action</h2>
          <div className="aspect-w-16 aspect-h-9 bg-gray-100 rounded-xl flex items-center justify-center">
            <div className="text-center">
              <p className="text-xl text-gray-600">Demo Video Player</p>
              {/* Add your video player component here */}
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">AI-Powered Landing Page Features</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <feature.icon className="h-12 w-12 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Interactive Preview Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Design for Conversion</h2>
          <div className="flex justify-center mb-8">
            <div className="flex gap-4 bg-gray-100 p-2 rounded-lg">
              <button
                onClick={() => setActiveTab('conversion')}
                className={`px-4 py-2 rounded-lg ${
                  activeTab === 'conversion' 
                    ? 'bg-white shadow-sm text-green-600' 
                    : 'text-gray-600'
                }`}
              >
                Conversion Elements
              </button>
              <button
                onClick={() => setActiveTab('components')}
                className={`px-4 py-2 rounded-lg ${
                  activeTab === 'components' 
                    ? 'bg-white shadow-sm text-green-600' 
                    : 'text-gray-600'
                }`}
              >
                Smart Components
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {(activeTab === 'conversion' ? conversionElements : smartComponents).map((element, index) => (
              <div 
                key={index}
                className="bg-green-50 p-6 rounded-xl text-center hover:bg-green-100 transition-colors"
              >
                <LayoutTemplate className="h-8 w-8 text-green-600 mx-auto mb-3" />
                <h3 className="font-medium">{element}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Workflow Section */}
      <div className="py-16 bg-gradient-to-r from-green-50 to-emerald-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Your Landing Page Journey</h2>
          <div className="relative">
            <div className="absolute top-1/2 left-0 right-0 h-1 bg-green-200 -translate-y-1/2 hidden md:block"></div>
            <div className="grid md:grid-cols-4 gap-8">
              {[
                {
                  icon: Wand2,
                  title: "Generate",
                  description: "Describe your product and audience"
                },
                {
                  icon: Shuffle,
                  title: "Customize",
                  description: "Modify content and design"
                },
                {
                  icon: LineChart,
                  title: "Optimize",
                  description: "Enhance for conversion"
                },
                {
                  icon: Rocket,
                  title: "Launch",
                  description: "Publish and track results"
                }
              ].map((step, index) => (
                <div key={index} className="relative">
                  <div className="bg-white rounded-xl p-6 text-center">
                    <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                      <step.icon className="h-6 w-6 text-green-600" />
                    </div>
                    <h3 className="font-semibold mb-2">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Success Stories</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16 bg-white">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Final CTA */}
      <div className="bg-gradient-to-r from-green-600 to-emerald-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Build Your Perfect Landing Page?</h2>
          <p className="text-xl mb-8">Join thousands of businesses creating high-converting pages with AI</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-green-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Building Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPageWriterLanding;