import React from 'react';
import { Helmet } from 'react-helmet';
import getCSPMeta from '../config/security';

const CSPHead = () => {
  const cspMeta = getCSPMeta();
  
  const formatCSP = (cspObject) => {
    return Object.entries(cspObject)
      .map(([key, values]) => `${key} ${values.join(' ')}`)
      .join('; ');
  };

  return (
    <Helmet>
      <meta
        httpEquiv="Content-Security-Policy"
        content={formatCSP(cspMeta)}
      />
    </Helmet>
  );
};

export default CSPHead;