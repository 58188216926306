import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Search,
  ArrowRight,
  Star,
  Target,
  BarChart2,
  Globe,
  Trophy,
  ChevronDown,
  LineChart,
  Settings,
  FileText,
  Zap,
  Users,
  Eye,
  TrendingUp,
  AlertCircle,
  Clock,
  CheckCircle
} from 'lucide-react';

const SeoOptimizerLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedTab, setSelectedTab] = useState('onPage');

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const features = [
    {
      title: "Real-time Analysis",
      description: "Instant SEO scoring and recommendations as you type",
      icon: Zap
    },
    {
      title: "Competitor Tracking",
      description: "Analyze and outperform your top competitors",
      icon: Target
    },
    {
      title: "Keyword Opportunities",
      description: "Discover high-potential keywords and topics",
      icon: TrendingUp
    },
    {
      title: "Content Optimization",
      description: "AI-powered suggestions for better rankings",
      icon: FileText
    },
    {
      title: "Technical SEO Audit",
      description: "Comprehensive website health checking",
      icon: Settings
    },
    {
      title: "Performance Tracking",
      description: "Monitor your SEO progress over time",
      icon: LineChart
    }
  ];

  const seoMetrics = [
    {
      label: "Average Ranking Improvement",
      value: "67%",
      icon: TrendingUp
    },
    {
      label: "Organic Traffic Increase",
      value: "143%",
      icon: Users
    },
    {
      label: "Conversion Rate Growth",
      value: "83%",
      icon: Target
    }
  ];

  const optimizationTypes = {
    onPage: [
      "Title Tag Optimization",
      "Meta Description Analysis",
      "Header Structure",
      "Content Quality Score",
      "Image ALT Tags",
      "URL Structure",
      "Internal Linking"
    ],
    technical: [
      "Page Speed Analysis",
      "Mobile Optimization",
      "Schema Markup",
      "XML Sitemap",
      "Robots.txt",
      "SSL Certificate",
      "Canonical Tags"
    ],
    content: [
      "Keyword Density",
      "Content Length",
      "Readability Score",
      "Topic Clusters",
      "Content Gap Analysis",
      "Semantic Keywords",
      "Featured Snippet Optimization"
    ]
  };

  const testimonials = [
    {
      name: "Michael Zhang",
      role: "SEO Director",
      company: "Digital Agency",
      content: "This tool has revolutionized our SEO workflow. We've seen our clients' rankings improve dramatically within months.",
      metrics: "Average 156% increase in organic traffic"
    },
    {
      name: "Rachel Thompson",
      role: "Content Strategist",
      company: "E-commerce Platform",
      content: "The AI-powered content suggestions have been game-changing. Our content now ranks for terms we never thought possible.",
      metrics: "Ranked #1 for 45+ target keywords"
    },
    {
      name: "Alex Martinez",
      role: "Marketing Manager",
      company: "SaaS Company",
      content: "Finally, an SEO tool that provides actionable insights instead of just data. Our organic search visibility has doubled.",
      metrics: "200% increase in qualified leads"
    }
  ];

  const faqs = [
    {
      question: "How does the AI-powered SEO optimization work?",
      answer: "Our AI analyzes over 200+ ranking factors in real-time, comparing your content against top-performing pages in your niche. It provides specific, actionable recommendations based on current SEO best practices and search engine algorithms."
    },
    {
      question: "How often is the tool updated with new SEO trends?",
      answer: "We continuously update our algorithm based on the latest search engine changes and industry trends. Our AI model is retrained weekly with fresh data from millions of search results."
    },
    {
      question: "Can it help with local SEO optimization?",
      answer: "Yes! We have specialized features for local SEO including local keyword research, Google Business Profile optimization, and location-based competitor analysis."
    },
    {
      question: "How does it compare to other SEO tools?",
      answer: "Unlike traditional SEO tools, we provide real-time optimization suggestions while you work. Our AI doesn't just analyze data – it provides specific, actionable steps to improve your rankings."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">AI-Powered</span>
          <span className="block text-green-600">SEO Optimizer</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Optimize your content in real-time with AI that understands search engines. Get higher rankings, more traffic, and better conversions.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-green-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-green-700 transition-all flex items-center"
          >
            Start Optimizing Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#demo"
            className="border border-green-600 text-green-600 px-8 py-3 rounded-lg font-medium hover:bg-green-50 transition-all"
          >
            Live Demo
          </a>
        </div>

        {/* Real-time Stats */}
        <div className="mt-12 bg-white rounded-xl shadow-lg p-6 max-w-4xl mx-auto">
          <h3 className="text-lg font-semibold mb-4">Real-time Optimization Impact</h3>
          <div className="grid md:grid-cols-3 gap-8">
            {seoMetrics.map((metric, index) => (
              <div key={index} className="flex flex-col items-center">
                <metric.icon className="h-8 w-8 text-green-500 mb-2" />
                <span className="text-3xl font-bold text-green-600">{metric.value}</span>
                <span className="text-sm text-gray-600">{metric.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Comprehensive SEO Tools Suite</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <feature.icon className="h-12 w-12 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Interactive Optimization Types */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Complete SEO Coverage</h2>
          <div className="flex justify-center space-x-4 mb-8">
            {Object.keys(optimizationTypes).map((type) => (
              <button
                key={type}
                onClick={() => setSelectedTab(type)}
                className={`px-6 py-2 rounded-lg font-medium transition-all ${
                  selectedTab === type
                    ? 'bg-green-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)} SEO
              </button>
            ))}
          </div>
          <div className="grid md:grid-cols-4 gap-4">
            {optimizationTypes[selectedTab].map((item, index) => (
              <div 
                key={index}
                className="bg-green-50 p-4 rounded-xl flex items-center space-x-2"
              >
                <CheckCircle className="h-5 w-5 text-green-600" />
                <span className="font-medium">{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Success Stories */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Success Stories</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div className="bg-green-50 p-3 rounded-lg mb-4">
                  <p className="text-green-700 font-medium">{testimonial.metrics}</p>
                </div>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Real-time SEO Score Demo */}
      <div id="demo" className="bg-gradient-to-r from-green-100 to-emerald-50 py-16">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">See Your SEO Score in Real-Time</h2>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <span className="text-lg font-medium">Live SEO Analysis</span>
              <div className="flex items-center">
                <Clock className="h-5 w-5 text-green-500 mr-2" />
                <span className="text-sm text-gray-600">Analyzing in real-time</span>
              </div>
            </div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
              <div className="h-4 bg-gray-200 rounded animate-pulse w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded animate-pulse w-1/2"></div>
            </div>
            <div className="mt-6 grid grid-cols-3 gap-4">
              <div className="bg-green-50 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">SEO Score</span>
                  <span className="text-lg font-bold text-green-600">92/100</span>
                </div>
              </div>
              <div className="bg-yellow-50 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Issues Found</span>
                  <span className="text-lg font-bold text-yellow-600">3</span>
                </div>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">Opportunities</span>
                  <span className="text-lg font-bold text-blue-600">7</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-green-600 to-emerald-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Improve Your Search Rankings?</h2>
          <p className="text-xl mb-8">Join thousands of websites achieving SEO success with AI-powered optimization</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-green-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Optimizing Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SeoOptimizerLanding;