import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MousePointer,
  TrendingUp,
  ArrowRight,
  ChevronDown,
  Zap,
  Target,
  RefreshCw,
  Layout,
  Maximize2,
  Minimize2,
  Copy,
  Palette,
  Shuffle,
  CheckCircle
} from 'lucide-react';

const CTAGeneratorLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [activePreview, setActivePreview] = useState('classic');

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const ctaVariants = {
    classic: {
      text: "Start Your Free Trial",
      style: "bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg"
    },
    urgent: {
      text: "Limited Time Offer - Join Now",
      style: "bg-red-600 hover:bg-red-700 text-white px-6 py-3 rounded-lg"
    },
    minimal: {
      text: "Get Started →",
      style: "bg-gray-900 hover:bg-gray-800 text-white px-6 py-3 rounded-lg"
    }
  };

  const faqs = [
    {
      question: "How does the CTA Generator create effective buttons?",
      answer: "Our AI analyzes your target audience, industry, and conversion goals to generate CTAs that drive action. It uses data from millions of successful buttons to recommend optimal text, design, and placement."
    },
    {
      question: "Can I customize the generated CTAs?",
      answer: "Yes! You can modify every aspect of the generated CTAs including text, colors, size, font, and animation effects. The generator provides both the visual design and the code implementation."
    },
    {
      question: "How many CTAs can I generate?",
      answer: "Our platform allows unlimited CTA generation. You can create multiple variants for A/B testing and optimize based on performance metrics."
    },
    {
      question: "Do I need coding knowledge to use the generated CTAs?",
      answer: "No coding knowledge required! We provide ready-to-use code snippets for all major platforms (HTML, React, WordPress, etc.) that you can simply copy and paste."
    }
  ];

  const conversionTips = [
    {
      title: "Action-Focused Copy",
      description: "Begin with strong verbs that drive immediate action",
      icon: MousePointer
    },
    {
      title: "Color Psychology",
      description: "Use colors that trigger desired emotional responses",
      icon: Palette
    },
    {
      title: "Smart Positioning",
      description: "Strategic button placement for maximum visibility",
      icon: Target
    },
    {
      title: "A/B Testing Ready",
      description: "Generate variants to find the highest performer",
      icon: Shuffle
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 py-20">
          <div className="text-center">
            <div className="inline-block mb-6">
              <span className="bg-purple-100 text-purple-800 px-4 py-2 rounded-full text-sm font-medium">
                Convert More Visitors Into Customers
              </span>
            </div>
            <h1 className="text-6xl font-bold mb-6">
              Craft the Perfect
              <span className="block text-purple-600">Call-to-Action</span>
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Generate high-converting CTAs in seconds. Powered by AI and backed by conversion rate optimization research.
            </p>
            <div className="flex justify-center gap-6">
              <Link
                to="/register"
                className="bg-purple-600 text-white px-8 py-4 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
              >
                Create Your First CTA
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <a
                href="#live-preview"
                className="border-2 border-purple-600 text-purple-600 px-8 py-4 rounded-lg font-medium hover:bg-purple-50 transition-all"
              >
                View Live Examples
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Live Preview Section */}
      <div id="live-preview" className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">See CTAs in Action</h2>
          <div className="bg-gray-50 rounded-2xl p-8">
            <div className="flex justify-center gap-4 mb-8">
              {Object.keys(ctaVariants).map((variant) => (
                <button
                  key={variant}
                  onClick={() => setActivePreview(variant)}
                  className={`px-4 py-2 rounded-lg capitalize ${
                    activePreview === variant
                      ? 'bg-purple-100 text-purple-800'
                      : 'bg-white text-gray-600'
                  }`}
                >
                  {variant}
                </button>
              ))}
            </div>
            <div className="bg-white p-12 rounded-xl shadow-sm text-center">
              <button className={ctaVariants[activePreview].style}>
                {ctaVariants[activePreview].text}
              </button>
              <div className="mt-4">
                <button className="text-gray-500 flex items-center mx-auto">
                  <Copy className="h-4 w-4 mr-2" />
                  Copy Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="py-20 bg-gradient-to-r from-purple-50 to-pink-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Everything You Need for Perfect CTAs</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="bg-purple-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
                <Zap className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Smart Text Generation</h3>
              <p className="text-gray-600">
                AI-powered copy that speaks directly to your audience's motivations and pain points.
              </p>
              <ul className="mt-6 space-y-3">
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Action-oriented verbs
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  FOMO triggers
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Value propositions
                </li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="bg-purple-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
                <Palette className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Visual Optimization</h3>
              <p className="text-gray-600">
                Beautiful, conversion-optimized designs that catch attention and drive clicks.
              </p>
              <ul className="mt-6 space-y-3">
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Color psychology
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Button animations
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Responsive design
                </li>
              </ul>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-sm">
              <div className="bg-purple-100 w-12 h-12 rounded-lg flex items-center justify-center mb-6">
                <RefreshCw className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">A/B Testing</h3>
              <p className="text-gray-600">
                Generate multiple variants and track their performance to optimize conversion rates.
              </p>
              <ul className="mt-6 space-y-3">
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Performance tracking
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Click-through rates
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                  Conversion metrics
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Conversion Tips */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Conversion-Boosting Tips</h2>
            <p className="text-gray-600">Built-in best practices from top conversion rate experts</p>
          </div>
          <div className="grid md:grid-cols-4 gap-8">
            {conversionTips.map((tip, index) => (
              <div key={index} className="text-center">
                <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <tip.icon className="h-6 w-6 text-purple-600" />
                </div>
                <h3 className="font-semibold mb-2">{tip.title}</h3>
                <p className="text-gray-600 text-sm">{tip.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="bg-purple-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-12">
            <div className="text-center">
              <div className="text-4xl font-bold mb-2">47%</div>
              <p className="text-purple-200">Average Click-Through Increase</p>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold mb-2">1M+</div>
              <p className="text-purple-200">CTAs Generated</p>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold mb-2">12,000+</div>
              <p className="text-purple-200">Happy Customers</p>
            </div>
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full px-6 py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="px-6 pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Final CTA */}
      <div className="bg-gradient-to-r from-purple-600 to-pink-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Create Converting CTAs?</h2>
          <p className="text-xl mb-8">Join thousands of marketers improving their conversion rates</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Generating CTAs
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <p className="mt-4 text-purple-100">14-day free trial • No credit card required</p>
        </div>
      </div>
    </div>
  );
};

export default CTAGeneratorLanding;