
import TemplateService from '../services/TemplateService';
import {
    FileText,
    MessageCircle,
    Layout,
    Mail,
    Briefcase,
    Code,
    Presentation,
    ShoppingBag,
    BookOpen,
    Search,
    Star,
    Building
  } from 'lucide-react';
  
  export const TEMPLATE_CATEGORIES = {
    'long-form': {
      icon: FileText,
      label: 'Long-Form Content',
      description: 'Create comprehensive articles and long-form content',
      urlPrefix: '/tools'
    },
    'marketing': {
      icon: MessageCircle,
      label: 'Marketing Content',
      description: 'Generate compelling marketing materials',
      urlPrefix: '/tools'
    },
    'website': {
      icon: Layout,
      label: 'Website Content',
      description: 'Create professional website copy',
      urlPrefix: '/tools'
    },
    'business': {
      icon: Briefcase,
      label: 'Business Writing',
      description: 'Professional business documents',
      urlPrefix: '/tools'
    },
    'technical': {
      icon: Code,
      label: 'Technical Writing',
      description: 'Technical documentation and guides',
      urlPrefix: '/tools'
    },
    'marketing-materials': {
      icon: Presentation,
      label: 'Marketing Materials',
      description: 'Create marketing collateral',
      urlPrefix: '/tools'
    },
    'ecommerce': {
      icon: ShoppingBag,
      label: 'E-commerce Content',
      description: 'Product descriptions and listings',
      urlPrefix: '/tools'
    },
    'academic': {
      icon: BookOpen,
      label: 'Academic Content',
      description: 'Academic papers and research',
      urlPrefix: '/tools'
    },
    'seo': {
      icon: Search,
      label: 'SEO Tools',
      description: 'Optimize content for search engines',
      urlPrefix: '/tools'
    },
    'specialized': {
      icon: Star,
      label: 'Specialized Content',
      description: 'Specific industry content',
      urlPrefix: '/tools'
    }
  };
  
  export const generateSlug = (str) => {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };
  
  // In templateConfig.js

  export const getTemplatePath = (template) => {
    // Handle special routes
    const specialRoutes = {
      'ai-rewriter': '/rewriter',
      'human-writer': '/human-writer'
    };
  
    if (template.identifier in specialRoutes) {
      return specialRoutes[template.identifier];
    }
    
    const category = TEMPLATE_CATEGORIES[template.category];
    if (!category) return null;
    
    const slug = generateSlug(template.name);
    return `${category.urlPrefix}/${slug}`;
  };
  
  export const getTemplateByPath = async (path) => {
    const templates = await TemplateService.fetchTemplates();
    if (!templates) return null;
  
    return Object.values(templates).find(template => {
      const templatePath = getTemplatePath(template);
      return templatePath && templatePath.endsWith(path);
    });
  };
  
  export const getTemplateIcon = (categoryId) => {
    const category = TEMPLATE_CATEGORIES[categoryId];
    return category ? category.icon : Star;
  };
  
  export default TEMPLATE_CATEGORIES;