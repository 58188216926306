// src/pages/ComingSoon.js
import React from 'react';
import { 
  Image, 
  Sparkles, 
  ArrowLeft,
  Camera,
  Paintbrush,
  Wand2 
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ComingSoon = () => {
  const navigate = useNavigate();

  const features = [
    {
      icon: Camera,
      title: 'AI Image Generation',
      description: 'Create unique images from text descriptions'
    },
    {
      icon: Paintbrush,
      title: 'Style Customization',
      description: 'Choose from multiple artistic styles'
    },
    {
      icon: Wand2,
      title: 'Image Enhancement',
      description: 'Enhance and modify existing images'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-16 px-4">
      <div className="max-w-4xl mx-auto text-center">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="mb-8 inline-flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Dashboard
        </button>

        {/* Main Content */}
        <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12 relative overflow-hidden">
          {/* Decorative Elements */}
          <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-purple-500 to-blue-500" />
          
          <div className="relative">
            {/* Icon */}
            <div className="mx-auto w-16 h-16 bg-purple-100 rounded-xl flex items-center justify-center mb-6">
              <Image className="h-8 w-8 text-purple-600" />
            </div>

            {/* Title */}
            <div className="flex items-center justify-center mb-4">
              <h1 className="text-3xl font-bold text-gray-900">AI Image Generation</h1>
              <div className="ml-3 flex-shrink-0">
                <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
                  <Sparkles className="h-4 w-4 mr-1 animate-pulse" />
                  Coming Soon
                </span>
              </div>
            </div>

            {/* Description */}
            <p className="text-xl text-gray-600 mb-12">
              We're working on something amazing! Transform your ideas into stunning visuals with our advanced AI image generation tools.
            </p>

            {/* Features Grid */}
            <div className="grid md:grid-cols-3 gap-8 mb-12">
              {features.map((feature, index) => (
                <div 
                  key={index}
                  className="bg-gray-50 rounded-xl p-6 transform transition-transform hover:scale-105"
                >
                  <feature.icon className="h-8 w-8 text-purple-600 mb-4" />
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>

            {/* Notification Sign Up */}
            <div className="bg-purple-50 rounded-xl p-6 mb-8">
              <h3 className="text-lg font-semibold text-purple-900 mb-4">
                Get Notified When We Launch
              </h3>
              <div className="flex gap-4 max-w-md mx-auto">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-1 px-4 py-2 border border-purple-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                <button className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors">
                  Notify Me
                </button>
              </div>
            </div>

            {/* Progress Indicator */}
            <div className="flex items-center justify-center space-x-2 text-sm text-gray-600">
              <div className="w-2 h-2 rounded-full bg-green-500" />
              <span>In Development</span>
              <span className="mx-2">•</span>
              <span>Launch Expected Q2 2024</span>
            </div>
          </div>
        </div>

        {/* Additional Info */}
        <div className="mt-8 text-center text-gray-600">
          <p>
            Have suggestions for our AI Image Generator?{' '}
            <button className="text-purple-600 hover:text-purple-700">
              Share your ideas
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;