import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  PenTool,
  Target,
  Shuffle,
  Layout,
  ChevronDown,
  Eye,
  Layers,
  Zap,
  MessageSquare,
  ScrollText,
  Globe,
  Paintbrush,
  ChevronRight,
  Star,
  Heart,
  Code,
  Search
} from 'lucide-react';

const FeaturesPageWriter = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [activeExample, setActiveExample] = useState('saas');

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const exampleFeatures = {
    saas: {
      title: "Project Management Software",
      features: [
        {
          before: "Task Management - Create and assign tasks",
          after: "Intelligent Task Orchestration - Streamline workflows with AI-powered task creation, smart assignments, and automated dependencies tracking"
        },
        {
          before: "Team Chat - Communicate with team members",
          after: "Real-time Collaboration Hub - Foster seamless team synergy with integrated chat, video calls, and contextual thread discussions"
        }
      ]
    },
    ecommerce: {
      title: "Online Store Platform",
      features: [
        {
          before: "Product Upload - Add products to your store",
          after: "Advanced Product Catalog Management - Bulk upload products with AI-enhanced descriptions and automatic category classification"
        },
        {
          before: "Shopping Cart - Add items to cart",
          after: "Smart Shopping Experience - Personalized product recommendations and one-click checkout with saved preferences"
        }
      ]
    }
  };

  const writingStyles = [
    {
      name: "Compelling",
      description: "Benefits-focused writing that highlights value",
      icon: Heart,
      color: "pink"
    },
    {
      name: "Technical",
      description: "Detailed specifications and capabilities",
      icon: Code,
      color: "blue"
    },
    {
      name: "SEO-Optimized",
      description: "Search engine friendly feature descriptions",
      icon: Search,
      color: "green"
    },
    {
      name: "User-Centric",
      description: "Problem-solution focused writing",
      icon: Target,
      color: "purple"
    }
  ];

  const processSteps = [
    {
      title: "Analyze",
      description: "AI scans your existing features and competition",
      icon: Eye,
      color: "blue"
    },
    {
      title: "Generate",
      description: "Create compelling feature descriptions",
      icon: PenTool,
      color: "green"
    },
    {
      title: "Optimize",
      description: "Enhance for conversion and SEO",
      icon: Target,
      color: "orange"
    },
    {
      title: "Review",
      description: "Fine-tune and perfect the content",
      icon: MessageSquare,
      color: "purple"
    }
  ];

  const benefits = [
    {
      metric: "Conversion Rate",
      increase: "+75%",
      description: "Higher feature page conversion rates"
    },
    {
      metric: "Time Saved",
      increase: "85%",
      description: "Faster feature page creation"
    },
    {
      metric: "SEO Traffic",
      increase: "+120%",
      description: "Increased organic search visibility"
    }
  ];

  const capabilities = [
    {
      title: "AI Writing Styles",
      features: ["Technical", "Marketing", "User-focused", "SEO-optimized"]
    },
    {
      title: "Content Elements",
      features: ["Benefits", "Use cases", "Technical specs", "Social proof"]
    },
    {
      title: "Optimization Tools",
      features: ["Keyword research", "Readability scoring", "A/B testing", "Analytics"]
    }
  ];

  const faqs = [
    {
      question: "How does the Features Page Writer create unique content?",
      answer: "Our AI analyzes your product, target audience, and industry to generate unique, compelling feature descriptions. It combines market research, competitive analysis, and conversion optimization principles to create content that resonates with your users and drives action."
    },
    {
      question: "Can I customize the writing style and tone?",
      answer: "Yes! You can choose from multiple writing styles including technical, marketing-focused, user-centric, or create your own custom tone. The AI adapts to match your brand voice and target audience preferences."
    },
    {
      question: "Does it optimize for SEO automatically?",
      answer: "The tool automatically incorporates relevant keywords, proper heading structure, and schema markup for better search engine visibility. It also suggests related terms and phrases to improve topical relevance."
    },
    {
      question: "How many revisions can I make to the generated content?",
      answer: "You have unlimited revisions to fine-tune the generated content. The AI learns from your edits to better match your preferences in future generations."
    },
    {
      question: "Can it handle multiple products or features at once?",
      answer: "Yes, you can bulk generate feature descriptions for multiple products or features simultaneously. The AI maintains consistency while ensuring each description is unique and valuable."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Transform Basic Features into</span>
          <span className="block text-purple-600">Compelling Value Propositions</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          AI-powered feature page writer that turns simple product descriptions into conversion-driving content. Boost engagement by 75%.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Write Features Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#examples"
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            View Examples
          </a>
        </div>
      </div>

      {/* Writing Styles */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Multiple Writing Styles</h2>
        <div className="grid md:grid-cols-4 gap-8">
          {writingStyles.map((style, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <style.icon className={`h-12 w-12 text-${style.color}-600 mb-4`} />
              <h3 className="text-xl font-semibold mb-2">{style.name}</h3>
              <p className="text-gray-600">{style.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Interactive Examples */}
      <div id="examples" className="bg-white py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">See the Transformation</h2>
          <div className="flex justify-center gap-4 mb-8">
            {Object.keys(exampleFeatures).map((type) => (
              <button
                key={type}
                onClick={() => setActiveExample(type)}
                className={`px-6 py-2 rounded-full ${
                  activeExample === type
                    ? 'bg-purple-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>
          <div className="bg-gray-50 rounded-xl p-8">
            <h3 className="text-2xl font-semibold mb-6 text-center">
              {exampleFeatures[activeExample].title}
            </h3>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <h4 className="text-lg font-semibold text-gray-500">Basic Features</h4>
                {exampleFeatures[activeExample].features.map((feature, index) => (
                  <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
                    {feature.before}
                  </div>
                ))}
              </div>
              <div className="space-y-6">
                <h4 className="text-lg font-semibold text-purple-600">AI-Enhanced Features</h4>
                {exampleFeatures[activeExample].features.map((feature, index) => (
                  <div key={index} className="bg-purple-50 p-4 rounded-lg shadow-sm">
                    {feature.after}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Process Steps */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
        <div className="grid md:grid-cols-4 gap-8">
          {processSteps.map((step, index) => (
            <div key={index} className="relative">
              <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
                <step.icon className={`h-12 w-12 text-${step.color}-600 mb-4`} />
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
              {index < processSteps.length - 1 && (
                <ChevronRight className="hidden md:block absolute top-1/2 -right-6 transform -translate-y-1/2 text-gray-400 h-8 w-8" />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Benefits */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-white mb-12">Proven Results</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white rounded-xl p-8 text-center">
                <div className="text-4xl font-bold text-purple-600 mb-2">
                  {benefit.increase}
                </div>
                <div className="text-xl font-semibold mb-2">{benefit.metric}</div>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Advanced Capabilities */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Advanced Capabilities</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {capabilities.map((capability, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg p-6">
                <h3 className="text-xl font-semibold mb-4">{capability.title}</h3>
                <ul className="space-y-3">
                  {capability.features.map((feature, i) => (
                    <li key={i} className="flex items-center text-gray-600">
                      <Star className="h-5 w-5 text-yellow-500 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="bg-white py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Feature Pages?</h2>
          <p className="text-xl mb-8">Join thousands of businesses creating compelling feature descriptions</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Writing Features
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturesPageWriter;