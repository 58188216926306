import React, { useEffect } from 'react';
import { Scale, AlertCircle } from 'lucide-react';

const Terms = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <div className="flex justify-center mb-4">
            <Scale className="h-12 w-12 text-purple-600 " />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 ">
            Terms of Service
          </h1>
          <p className="text-lg text-gray-600 ">
            Last Updated: November 12, 2024
          </p>
        </div>

        {/* Legal Agreement Notice */}
        <div className="bg-purple-50 rounded-xl p-6 mb-8">
          <div className="flex items-start">
            <AlertCircle className="h-6 w-6 text-purple-600 mt-1 mr-3 flex-shrink-0" />
            <div>
              <p className="text-purple-900  font-semibold mb-2">
                PLEASE READ THESE TERMS OF SERVICE CAREFULLY
              </p>
              <p className="text-purple-800 text-sm">
                BY ACCESSING OR USING THE CONTENT GPT SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE OUR SERVICES.
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm p-8">
          <div className="prose max-w-none">
          <h2 className="text-2xl font-bold mt-8 mb-6"> 1. AGREEMENT TO TERMS</h2>
            
            <p>These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and The Content GPT ("we," "us," or "our"), concerning your access to and use of the https://thecontentgpt.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "Site" and "Services").</p>
            
            <p>You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Terms of Service. If you do not agree with all of these Terms of Service, then you are expressly prohibited from using the Services and you must discontinue use immediately. Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference.</p>
            
            <p>We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Service at any time and for any reason. We will alert you about any changes by updating the "Last Updated" date of these Terms of Service, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Service to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Service by your continued use of the Services after the date such revised Terms of Service are posted.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">2. ELIGIBILITY</h2>
            
            <p>The Services are intended for users who are at least 18 years of age. By using the Services, you represent and warrant that: (1) you are at least 18 years of age; (2) you have the legal capacity and agree to comply with these Terms of Service; (3) you are not a minor in the jurisdiction in which you reside; (4) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise; (5) you will not use the Services for any illegal or unauthorized purpose; and (6) your use of the Services will not violate any applicable law or regulation.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">3. INTELLECTUAL PROPERTY RIGHTS</h2>
            
            <p>Unless otherwise indicated, the Services and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Services (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions.</p>
            
            <p>The Content and the Marks are provided on the Services "AS IS" for your information and personal use only. Except as expressly provided in these Terms of Service, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">4. USER REGISTRATION AND ACCOUNTS</h2>
            
            <p>You may be required to register with the Services to access certain features. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
            
            <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">5. SERVICES AND SUBSCRIPTION</h2>
            
            <p>The Content GPT provides AI-powered content generation services through various subscription plans. Each subscription plan includes specific features, limitations, and terms of use. We reserve the right to modify, suspend, or discontinue any aspect of the Services at any time, including the availability of any feature, database, or Content.</p>
            
            <p>Subscription fees are billed in advance on a recurring and periodic basis (each period is called a "billing cycle"). Billing cycles are set on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a subscription. At the end of each billing cycle, your subscription will automatically renew under the exact same conditions unless you cancel it or we cancel it.</p>
            
            <p>By initiating a subscription, you authorize us to charge you the subscription fee at the then-current rate, and any other charges you may incur in connection with your use of the Services to the payment method you provided during registration. We reserve the right to adjust pricing for our Services or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">6. PAYMENT AND REFUND POLICY</h2>
            
            <p>We provide various payment options for our Services through trusted third-party payment processors. By providing your payment information, you represent and warrant that you have the legal right to use any credit card(s) or other payment method(s) in connection with any purchase. You agree that the information you provide to us is true, correct, and complete.</p>
            
            <p>Refund requests must be submitted within seven (7) days of the initial purchase. Eligibility for refunds is contingent upon usage remaining below specified thresholds, typically defined as less than 10,000 words generated or 5 images created. Refunds are processed at our sole discretion and may be denied if usage exceeds these limits or if there is evidence of abuse or violation of these Terms.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">7. USAGE LIMITATIONS AND FAIR USE</h2>
            
            <p>Our Services are subject to fair usage limitations to ensure equitable access for all users. We reserve the right to monitor usage patterns and implement restrictions if we detect excessive use or abuse of the Services. Usage that significantly exceeds normal patterns or suggests automated access may result in temporary or permanent suspension of service.</p>
            
            <p>You agree not to share, resell, or distribute access to your account or the Services. Each subscription is intended for use by a single user or entity unless explicitly stated otherwise in your subscription terms. Multiple users accessing a single account or systematic redistribution of generated content may result in immediate account termination without refund.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">8. PROHIBITED USES</h2>
            
            <p>You may use the Services only for lawful purposes and in accordance with these Terms of Service. You agree not to use the Services for any unlawful purpose or to violate any laws in your jurisdiction (including but not limited to copyright laws). You specifically agree not to: (1) systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory; (2) make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email; (3) circumvent, disable, or otherwise interfere with security-related features of the Services; (4) engage in unauthorized framing of or linking to the Services; (5) trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords; (6) interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services; (7) attempt to impersonate another user or person or use the username of another user; (8) sell or otherwise transfer your profile; (9) use any information obtained from the Services in order to harass, abuse, or harm another person; (10) use the Services as part of any effort to compete with us or otherwise use the Services for any revenue-generating endeavor or commercial enterprise.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">9. CONTENT GENERATION AND OWNERSHIP</h2>
            
            <p>The content generated through our Services is produced using advanced artificial intelligence models. While we strive to ensure high quality and originality of the generated content, we cannot guarantee that it will be entirely free from similarities to existing content or completely unique. You are responsible for reviewing, editing, and ensuring the appropriateness of any generated content for your intended use.</p>
            
            <p>Upon generation and delivery of content through our Services, and subject to your compliance with these Terms and payment of applicable fees, you are granted a worldwide, non-exclusive, fully paid-up, royalty-free license to use, modify, distribute, and create derivative works based on the generated content. However, this license does not transfer ownership of our intellectual property rights in our AI models, systems, or technology.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">10. THIRD-PARTY SERVICES AND CONTENT</h2>
            
            <p>The Services may contain links to third-party websites and services, and/or display content from third parties. These third-party services and content are not under our control, and we are not responsible for their content, functionality, or practices regarding privacy or otherwise. Your interactions with any third-party websites or services are solely between you and the third party.</p>
            
            <p>We do not warrant the offerings of any of these third parties or their websites. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">11. USER CONTENT AND FEEDBACK</h2>
            
            <p>You retain all rights in, and are solely responsible for, the content you submit to or through the Services ("User Content"). By providing User Content, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, modify, create derivative works based upon, distribute, and display your User Content for the purposes of operating and providing the Services.</p>
            
            <p>If you provide any feedback, suggestions, or recommendations regarding the Services ("Feedback"), you hereby grant us a perpetual, irrevocable, worldwide, non-exclusive, fully-paid and royalty-free license to use such Feedback for any purpose, including the incorporation of such Feedback into our Services or other products or services.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">12. DISCLAIMER OF WARRANTIES</h2>
            
            <p>THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
            
            <p>WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">13. LIMITATION OF LIABILITY</h2>
            
            <p>IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
            
            <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">14. INDEMNIFICATION</h2>
            
            <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of: (1) your use of the Services; (2) breach of these Terms of Service; (3) any breach of your representations and warranties set forth in these Terms of Service; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Services with whom you connected via the Services.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">15. TERMINATION</h2>
            
            <p>We may terminate or suspend your account and bar access to the Services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms of Service. Upon termination, your right to use the Services will immediately cease. If you wish to terminate your account, you may simply discontinue using the Services, or notify us that you wish to delete your account.</p>
            
            <p>All provisions of the Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">16. GOVERNING LAW</h2>
            
            <p>These Terms shall be governed by and defined following the laws of India. The Content GPT and yourself irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">17. DISPUTE RESOLUTION</h2>
            
            <p>Any dispute arising out of or relating to these Terms, or the breach thereof, shall be settled by arbitration administered by the International Chamber of Commerce in accordance with its arbitration rules. The arbitration shall be conducted by a single arbitrator. The place of arbitration shall be determined by mutual agreement of the parties, and if no agreement can be reached, shall be in India. The language of arbitration shall be English.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">18. MODIFICATIONS AND INTERRUPTIONS</h2>

            <p>We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.</p>

            <h2 className="text-2xl font-bold mt-8 mb-6">19. CONTACT US</h2>

            <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at support@thecontentgpt.com.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;