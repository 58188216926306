// Create new file at src/components/MarkdownToolbar.js

import React from 'react';

const MarkdownToolbar = ({ onInsert }) => {
  const buttons = [
    { label: 'H1', markdown: '# ' },
    { label: 'H2', markdown: '## ' },
    { label: 'H3', markdown: '### ' },
    { label: 'Bold', markdown: '**', isWrapper: true },
    { label: 'Italic', markdown: '_', isWrapper: true },
    { label: 'List', markdown: '- ' },
    { label: 'Numbered List', markdown: '1. ' },
    { label: 'Table', markdown: '\n| Header 1 | Header 2 |\n| --------- | --------- |\n| Cell 1 | Cell 2 |\n' },
    { label: 'Link', markdown: '[Link Text](url)', isWrapper: false },
    { label: 'Quote', markdown: '> ' },
  ];

  return (
    <div className="flex flex-wrap gap-2 mb-4 p-2 bg-gray-50 rounded-lg">
      {buttons.map((button) => (
        <button
          key={button.label}
          onClick={() => onInsert(button.markdown, button.isWrapper)}
          className="px-3 py-1 bg-white border border-gray-300 rounded hover:bg-gray-100"
          type="button"
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default MarkdownToolbar;