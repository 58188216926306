import React from 'react';
import { 
  DollarSign, 
  PieChart, 
  Clock, 
  Users,
  Gift,
  Link,
  ArrowRight,
  CheckCircle 
} from 'lucide-react';

const AffiliateProgram = () => {
  const benefits = [
    {
      title: "High Commission Rate",
      description: "Earn 30% commission on all referred sales, including recurring subscriptions.",
      icon: DollarSign
    },
    {
      title: "45-Day Cookie",
      description: "Get credited for sales up to 45 days after the initial referral.",
      icon: Clock
    },
    {
      title: "Real-Time Analytics",
      description: "Track your performance with detailed analytics and reporting.",
      icon: PieChart
    },
    {
      title: "Dedicated Support",
      description: "Get priority support and resources to maximize your success.",
      icon: Users
    }
  ];

  const steps = [
    {
      title: "Sign Up",
      description: "Complete our quick application process to join the program."
    },
    {
      title: "Get Approved",
      description: "We'll review your application within 24 hours."
    },
    {
      title: "Share Links",
      description: "Get your unique referral links and promotional materials."
    },
    {
      title: "Earn Money",
      description: "Start earning 30% commission on all referred sales."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-b from-green-50 to-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl">
              Partner With Us
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Earn up to 30% commission by recommending The Content GPT to your audience
            </p>
            <div className="mt-8">
              <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                Join Our Affiliate Program
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Why Partner With Us?</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="text-center">
                <div className="bg-green-100 w-12 h-12 rounded-lg flex items-center justify-center mx-auto mb-4">
                  <benefit.icon className="h-6 w-6 text-green-600" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Commission Structure */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-gradient-to-r from-green-600 to-teal-600 rounded-2xl text-white p-8 md:p-12">
            <h2 className="text-3xl font-bold mb-6 text-center">Lucrative Commission Structure</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="text-center">
                <div className="text-4xl font-bold mb-2">30%</div>
                <p>Commission Rate</p>
              </div>
              <div className="text-center">
                <div className="text-4xl font-bold mb-2">45</div>
                <p>Day Cookie Duration</p>
              </div>
              <div className="text-center">
                <div className="text-4xl font-bold mb-2">$50+</div>
                <p>Average Commission Per Sale</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid md:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-green-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                  <span className="text-green-600 font-bold text-xl">{index + 1}</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
                {index < steps.length - 1 && (
                  <ArrowRight className="absolute top-6 right-0 h-6 w-6 text-gray-400 hidden md:block" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Marketing Resources */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Marketing Resources</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <Gift className="h-8 w-8 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Promotional Materials</h3>
              <p className="text-gray-600 mb-4">Access to banners, email templates, and social media content.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <Link className="h-8 w-8 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Custom Links</h3>
              <p className="text-gray-600 mb-4">Generate unique tracking links for different campaigns.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <PieChart className="h-8 w-8 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Performance Dashboard</h3>
              <p className="text-gray-600 mb-4">Track clicks, conversions, and earnings in real-time.</p>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-16 bg-white">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-8">
            <div>
              <h3 className="text-xl font-semibold mb-2">How does the commission structure work?</h3>
              <p className="text-gray-600">You earn 30% of every sale made through your referral link, including recurring subscriptions. Commissions are calculated on the total purchase amount.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">When do I get paid?</h3>
              <p className="text-gray-600">Payments are processed monthly for all earnings over $50. We support multiple payment methods including PayPal and bank transfer.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Who can join the affiliate program?</h3>
              <p className="text-gray-600">Our affiliate program is open to content creators, marketers, bloggers, and anyone with an audience that could benefit from our services.</p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-green-600 to-teal-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-8">Ready to Start Earning?</h2>
          <button className="inline-flex items-center px-6 py-3 border-2 border-white text-white text-lg font-medium rounded-md hover:bg-white hover:text-green-600 transition-colors">
            Apply Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AffiliateProgram;