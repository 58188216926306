import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Image, 
  Mail, 
  ArrowRight, 
  Share2, 
  AlertCircle,
  Check
} from 'lucide-react';

const ComingSoonImageTool = ({ 
  toolName = "Image Creation Tool",
  tagline = "Creating visual magic, coming soon.",
  expectedDate = "Winter 2024"
}) => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [daysLeft, setDaysLeft] = useState(30);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      // API call placeholder
      // await submitEmail(email);
      setIsSubmitted(true);
      setError('');
      setEmail('');
    } catch (err) {
      setError('Something went wrong. Please try again.');
    }
  };

  // Animation background squares
  const squares = Array(6).fill(null);

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {squares.map((_, index) => (
          <div
            key={index}
            className="absolute opacity-10 animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animation: `float ${10 + index * 2}s infinite`,
              animationDelay: `${index * 0.5}s`
            }}
          >
            <Image className="h-24 w-24 text-indigo-600" />
          </div>
        ))}
      </div>

      {/* Main Content */}
      <div className="relative z-10 max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="text-center">
          {/* Badge */}
          <div className="inline-flex items-center px-4 py-2 rounded-full bg-indigo-100 text-indigo-600 mb-8">
            <AlertCircle className="h-4 w-4 mr-2" />
            <span className="text-sm font-medium">Coming {expectedDate}</span>
          </div>

          {/* Hero Content */}
          <h1 className="text-5xl font-bold mb-6">
            <span className="block text-gray-900">{toolName}</span>
            <span className="block text-indigo-600 mt-2">{tagline}</span>
          </h1>

          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-12">
            We're working on something amazing. Be the first to know when we launch.
          </p>

          {/* Email Subscription */}
          <div className="max-w-md mx-auto mb-12">
            {!isSubmitted ? (
              <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4">
                <div className="flex-1">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  {error && (
                    <p className="text-red-500 text-sm mt-1 text-left">{error}</p>
                  )}
                </div>
                <button
                  type="submit"
                  className="bg-indigo-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-indigo-700 transition-colors flex items-center justify-center"
                >
                  Notify Me
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </form>
            ) : (
              <div className="bg-green-50 text-green-700 px-6 py-4 rounded-lg flex items-center justify-center">
                <Check className="h-5 w-5 mr-2" />
                <span>Thank you! We'll notify you when we launch.</span>
              </div>
            )}
          </div>

          {/* Share Buttons */}
          <div className="flex justify-center gap-4">
            <button 
              onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Excited for the upcoming launch of ${toolName}! 🚀`)}`)}
              className="flex items-center px-4 py-2 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors"
            >
              <Share2 className="h-5 w-5 mr-2" />
              Share
            </button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="absolute bottom-0 inset-x-0 bg-white/80 backdrop-blur-sm">
        <div className="max-w-6xl mx-auto px-4 py-6 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-gray-600 mb-4 sm:mb-0">
            © 2024 {toolName}. All rights reserved.
          </p>
          <div className="flex gap-6">
            <Link to="/privacy" className="text-gray-600 hover:text-gray-900">Privacy</Link>
            <Link to="/terms" className="text-gray-600 hover:text-gray-900">Terms</Link>
            <a href="mailto:support@thecontentgpt.com" className="text-gray-600 hover:text-gray-900">Contact</a>
          </div>
        </div>
      </div>

      {/* CSS for animations */}
      <style jsx="true">{`
        @keyframes float {
          0%, 100% {
            transform: translateY(0) rotate(0deg);
          }
          50% {
            transform: translateY(-20px) rotate(10deg);
          }
        }
        .animate-float {
          animation: float 10s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default ComingSoonImageTool;