// src/components/PaymentForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { usePaddle } from './PaddleProvider';
import { Loader2, AlertCircle } from 'lucide-react';

const PaymentForm = ({ plan, billingCycle = 'monthly' }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { isInitialized, openCheckout, error: paddleError } = usePaddle();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);  // Added this line

  const handlePayment = async () => {
    try {
      setLoading(true);
      setError('');
      setSuccess(false);
  
      if (!user) {
        localStorage.setItem('selectedPlan', JSON.stringify({
          name: plan.name,
          price: billingCycle === 'yearly' ? plan.price.yearly : plan.price.monthly,
          billingCycle
        }));
        navigate('/register');
        return;
      }
  
      const selectedPlanId = billingCycle === 'yearly' ? 
        (plan.name === 'PRO' ? 'pri_01jbpdmw74m84fvj0wjy55ez0j' : 'pri_01jbpdjkrg2smr4776wgy6y412') :
        (plan.name === 'PRO' ? 'pri_01jbpdkse8h93k227dkt2v2t3t' : 'pri_01jbpdg0rbj0grma59tqt0hqfp');
  
      const checkout = await openCheckout(selectedPlanId, {
        email: user.email,
        customerId: user.subscription?.customerId, // Include existing customer ID if available
        userId: user.id,
        planName: plan.name,
        billingCycle
      });

      if (checkout) {
        checkout.on('complete', async (data) => {
          try {
            setSuccess(true);
            // You can navigate to dashboard or show success message here
            navigate('/dashboard?subscription=success');
          } catch (err) {
            console.error('Subscription confirmation error:', err);
            setError('Failed to confirm subscription. Please contact support.');
          }
        });

        checkout.on('close', () => {
          setLoading(false);
        });
      }
    } catch (err) {
      console.error('Payment error:', err);
      setError(err.message || 'Failed to process payment');
      setLoading(false);
    }
  };

  return (
    <div className="mt-4">
      {(error || paddleError) && (
        <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/50 border border-red-200 dark:border-red-800 rounded-lg flex items-center text-red-700 dark:text-red-400">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error || paddleError}
        </div>
      )}

      {success && (
        <div className="mb-4 p-4 bg-green-50 dark:bg-green-900/50 border border-green-200 dark:border-green-800 rounded-lg flex items-center text-green-700 dark:text-green-400">
          <AlertCircle className="h-5 w-5 mr-2" />
          Subscription successful! Redirecting...
        </div>
      )}

      <button
        onClick={handlePayment}
        disabled={loading || !isInitialized}
        className={`w-full flex items-center justify-center px-4 py-3 border border-transparent text-sm font-medium rounded-lg ${
          plan.name === 'PRO'
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : 'bg-white text-blue-600 border-blue-600 hover:bg-blue-50'
        } transition-colors ${(!isInitialized || loading) ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {loading ? (
          <>
            <Loader2 className="animate-spin h-5 w-5 mr-2" />
            Processing...
          </>
        ) : !isInitialized ? (
          'Loading Payment System...'
        ) : (
          user ? `Subscribe ${billingCycle === 'yearly' ? 'Yearly' : 'Monthly'}` : 'Get Started'
        )}
      </button>
    </div>
  );
};

export default PaymentForm;