// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  English: {
    translation: {
      // Add your English translations here
      "settings": "Settings",
      "profileInformation": "Profile Information",
      "name": "Name",
      "email": "Email",
      "updateProfile": "Update Profile",
      // Add more translations as needed
    }
  },
  Spanish: {
    translation: {
      "settings": "Configuración",
      "profileInformation": "Información del Perfil",
      "name": "Nombre",
      "email": "Correo Electrónico",
      "updateProfile": "Actualizar Perfil",
      // Add more translations as needed
    }
  },
  // Add more languages as needed
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'English',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;