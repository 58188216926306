import React, { useState, useEffect } from 'react';
import apiService from '../services/apiService';
import { 
  Loader2, 
  AlertCircle,
  XCircle,
  Clock,
  Filter,
  X
} from 'lucide-react';

const ITEMS_PER_PAGE = 8;

const RecentActivity = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recentActivity, setRecentActivity] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');

  // Fetch activity data
  useEffect(() => {
    const fetchActivity = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Use apiService instead of direct fetch
        const response = await apiService.fetch(
          `/dashboard/recent-content?page=${currentPage}&limit=${ITEMS_PER_PAGE}&filter=${filter}`
        );

        if (response?.activities) {
          setRecentActivity(response.activities);
          setTotalPages(response.totalPages || 1);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        console.error('Error fetching recent activity:', err);
        setError('Failed to load recent activity');
        setRecentActivity([]);
      } finally {
        setLoading(false);
      }
    };

    fetchActivity();
  }, [currentPage, filter]);

  // Format feature name for display
  const formatFeatureName = (feature) => {
    return feature
      ?.split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') || 'Unknown Feature';
  };

  if (error) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
        <div className="p-6">
          <div className="flex items-center text-red-600 dark:text-red-400">
            <AlertCircle className="h-5 w-5 mr-2" />
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
        <div className="flex justify-center items-center p-8">
          <Loader2 className="h-8 w-8 animate-spin text-purple-600" />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
      <div className="p-6 border-b border-gray-200 dark:border-gray-700">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Recent Activity</h3>
          
          {/* Filter Dropdown */}
          <div className="flex items-center space-x-4">
            <select
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                setCurrentPage(1);
              }}
              className="px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg text-gray-700 dark:text-gray-200 text-sm"
            >
              <option value="all">All Features</option>
              <option value="content">Content Generation</option>
              <option value="rewriter">AI Rewriter</option>
              <option value="images">Images</option>
            </select>
          </div>
        </div>
      </div>
      
      {recentActivity.length > 0 ? (
        <>
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {recentActivity.map((activity, index) => (
              <div key={index} className="p-6">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                      {formatFeatureName(activity.feature)}
                    </h4>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                      {activity.description || 
                        `Generated ${activity.wordsGenerated?.toLocaleString() || 0} words`}
                    </p>
                  </div>
                  <div className="ml-6">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200">
                      {activity.pointsUsed} points
                    </span>
                  </div>
                </div>
                <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                  {new Date(activity.timestamp).toLocaleString()}
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="px-6 py-4 border-t border-gray-200 dark:border-gray-700">
              <div className="flex justify-center space-x-2">
                {[...Array(totalPages)].map((_, i) => (
                  <button
                    key={i + 1}
                    onClick={() => setCurrentPage(i + 1)}
                    className={`px-3 py-1 rounded-md text-sm font-medium ${
                      currentPage === i + 1
                        ? 'bg-purple-600 text-white'
                        : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="text-center py-12">
          <p className="text-gray-600 dark:text-gray-400">No recent activity found</p>
        </div>
      )}
    </div>
  );
};

export default RecentActivity;