import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentForm from '../components/PaymentForm';
import { 
  Check, 
  HelpCircle, 
  Shield, 
  Zap, 
  Users, 
  Clock,
  ArrowRight
} from 'lucide-react';

const PricingPage = () => {
  const navigate = useNavigate();
  const [billingCycle, setBillingCycle] = useState('monthly');

  // Pricing data
  const plans = {
    free: {
      name: 'Free',
      label: '',
      description: 'Perfect for trying out our services',
      price: { 
        monthly: 0,
        yearly: 0,
        yearlyMonthly: 0
      },
      features: [
        'Single Device Access',
        'Get 1,000 Words/mo',
        'AI Image Creation',
        'Basic Templates',
        'Community Support'
      ],
      action: 'Start Free'
    },
    starter: {
      name: 'STARTER',
      label: '',
      description: 'Great for content creators and small teams',
      price: { 
        monthly: 19,
        yearly: 180,
        yearlyMonthly: 15
      },
      features: [
        "Single Device Access",
        "50,000 Words/mo",
        "Limited Chat Words",
        "AI Image Creation - 50/mo",
        "Standard Quality Images",
        "200 Stock Images",
        "100+ Content Templates",
        "15+ Tones",
        "15+ Languages"
      ],
      action: 'Get Started'
    },
    pro: {
      name: 'PRO',
      label: 'Most Popular',
      description: 'Perfect for growing businesses',
      price: { 
        monthly: 79,
        yearly: 756,
        yearlyMonthly: 63
      },
      features: [
        "Multiple Device Access",
        "200,000 Words/mo",
        "Unlimited Chat Words",
        "AI Image Creation - 100/mo",
        "HD Quality Images",
        "500 Stock Images",
        "200+ Content Templates",
        "25+ Tones",
        "30+ Languages",
        "Priority Support"
      ],
      action: 'Get Started'
    }
  };

  const features = [
    {
      icon: Shield,
      title: 'Enterprise Security',
      description: '256-bit encryption and SOC 2 compliance'
    },
    {
      icon: Zap,
      title: 'Fast Generation',
      description: 'Get content in seconds, not minutes'
    },
    {
      icon: Users,
      title: 'Team Collaboration',
      description: 'Work together seamlessly'
    },
    {
      icon: Clock,
      title: '24/7 Support',
      description: 'Get help whenever you need it'
    }
  ];

  const handlePlanSelection = (plan) => {
    // Store selected plan details in localStorage
    localStorage.setItem('selectedPlan', JSON.stringify({
      name: plan.name,
      price: billingCycle === 'yearly' ? plan.price.yearlyMonthly : plan.price.monthly,
      cycle: billingCycle,
      totalPrice: billingCycle === 'yearly' ? plan.price.yearly : plan.price.monthly * 12
    }));

    // Redirect to registration
    navigate('/register');
  };

  const renderPrice = (plan) => {
    if (billingCycle === 'yearly') {
      return (
        <div className="text-center">
          <span className="text-5xl font-bold">${plan.price.yearlyMonthly}</span>
          <span className="text-gray-600 ml-2">/mo</span>
          <div className="text-sm text-gray-500 mt-1">
            Billed annually (${plan.price.yearly}/year)
          </div>
          {plan.price.yearlyMonthly < plan.price.monthly && (
            <div className="mt-2">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                Save {Math.round((1 - plan.price.yearlyMonthly / plan.price.monthly) * 100)}%
              </span>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="text-center">
        <span className="text-5xl font-bold">${plan.price.monthly}</span>
        <span className="text-gray-600 ml-2">/mo</span>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white pt-20 pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Simple, Transparent Pricing
            </h1>
            <p className="mt-5 text-xl text-gray-500">
              Choose the perfect plan for your content creation needs
            </p>
          </div>

          {/* Billing Toggle */}
          <div className="mt-12 flex justify-center">
            <div className="bg-gray-100 p-2 rounded-full inline-flex">
              <button
                onClick={() => setBillingCycle('monthly')}
                className={`px-6 py-2 rounded-full text-sm font-medium ${
                  billingCycle === 'monthly'
                    ? 'bg-white text-gray-900 shadow'
                    : 'text-gray-500 hover:text-gray-900'
                } transition-all`}
              >
                Monthly billing
              </button>
              <button
                onClick={() => setBillingCycle('yearly')}
                className={`px-6 py-2 rounded-full text-sm font-medium flex items-center ${
                  billingCycle === 'yearly'
                    ? 'bg-white text-gray-900 shadow'
                    : 'text-gray-500 hover:text-gray-900'
                } transition-all`}
              >
                Annual billing
                <span className="ml-2 bg-green-100 text-green-800 text-xs font-semibold px-2 py-0.5 rounded-full">
                  Save 20%
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-16">
        <div className="grid md:grid-cols-3 gap-8">
          {Object.entries(plans).map(([key, plan]) => (
            <div
              key={key}
              className={`relative bg-white rounded-2xl shadow-lg ${
                plan.label === 'Most Popular'
                  ? 'ring-2 ring-blue-600 scale-105 z-10'
                  : ''
              }`}
            >
              {plan.label && (
                <div className="absolute -top-5 left-0 right-0 flex justify-center">
                  <span className="bg-blue-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                    {plan.label}
                  </span>
                </div>
              )}

              <div className="p-8">
                <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
                <p className="mt-1 text-sm text-gray-500">{plan.description}</p>
                <div className="mt-6 mb-8">{renderPrice(plan)}</div>

                <PaymentForm 
                  plan={plan} 
                  billingCycle={billingCycle} // 'monthly' or 'yearly'
                />

                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 flex-shrink-0 mr-3" />
                      <span className="text-sm text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((Feature, index) => (
            <div key={index} className="text-center">
              <div className="inline-flex items-center justify-center h-12 w-12 rounded-md bg-blue-100 text-blue-600 mb-4">
                <Feature.icon className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {Feature.title}
              </h3>
              <p className="text-gray-500">{Feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-white">
        <div className="max-w-4xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">
            Frequently Asked Questions
          </h2>
          <dl className="space-y-8">
            <div>
              <dt className="text-lg font-medium text-gray-900 mb-4">
                What happens if I exceed my monthly word limit?
              </dt>
              <dd className="text-gray-500">
                You'll receive a notification when you reach 80% of your limit. 
                You can either upgrade your plan or purchase additional words as needed.
              </dd>
            </div>
            <div>
              <dt className="text-lg font-medium text-gray-900 mb-4">
                Can I change plans at any time?
              </dt>
              <dd className="text-gray-500">
                Yes, you can upgrade or downgrade your plan at any time. 
                Changes will be prorated for your billing cycle.
              </dd>
            </div>
            <div>
              <dt className="text-lg font-medium text-gray-900 mb-4">
                Do unused words roll over to the next month?
              </dt>
              <dd className="text-gray-500">
                No, word limits reset at the beginning of each billing cycle to 
                ensure consistent service quality.
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Enterprise CTA */}
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-blue-600 rounded-lg shadow-xl overflow-hidden">
            <div className="px-6 py-12 max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-white mb-4">
                Need a Custom Enterprise Solution?
              </h2>
              <p className="text-lg text-blue-100 mb-8">
                Get a tailored plan that fits your organization's specific needs. 
                Custom limits, advanced features, and dedicated support.
              </p>
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                Contact Sales
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;