import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  ChevronDown, 
  FileText, 
  Layout, 
  Image, 
  MessageSquare,
  RefreshCcw,
  Wand2,
  Menu,
  X,
  Settings,
  Shield
} from 'lucide-react';

const PublicNavbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(null);

  // Prevent body scroll when mobile menu is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  const menuCategories = {
    'Content Creation': {
      icon: FileText,
      tools: [
        { name: 'Blog Post Generator', path: '/landing/blog-writer' },
        { name: 'Social Media Writer', path: '/landing/social-media-writer' },
        { name: 'Email Newsletter Creator', path: '/landing/email-newsletter' },
        { name: 'Product Description Writer', path: '/landing/product-description-writer' }
      ]
    },
    'Content Enhancement': {
      icon: Wand2,
      tools: [
        { name: 'AI Detection Bypass', path: '/landing/human-writer' },
        { name: 'SEO Optimizer', path: '/landing/seo-optimizer' },
        { name: 'Content Rewriter', path: '/landing/content-rewriter' },
        { name: 'Readability Improver', path: '/landing/readability-improver' }
      ]
    },
    'Marketing Copy': {
      icon: MessageSquare,
      tools: [
        { name: 'Ad Copy Generator', path: '/landing/ad-copy-writer' },
        { name: 'Landing Page Writer', path: '/landing/landing-page-writer' },
        { name: 'Sales Copy Creator', path: '/landing/sales-copy-writer' },
        { name: 'CTAs Generator', path: '/landing/cta-generator' }
      ]
    },
    'Visual Content': {
      icon: Image,
      tools: [
        { name: 'Blog Images Creator', path: '/landing/image-generator' },
        { name: 'Social Media Graphics', path: '/landing/image-generator' },
        { name: 'Banner Designer', path: '/landing/image-generator' },
        { name: 'Logo Generator', path: '/landing/image-generator' }
      ]
    },
    'Website Content': {
      icon: Layout,
      tools: [
        { name: 'About Page Writer', path: '/landing/about-page-writer' },
        { name: 'Service Page Creator', path: '/landing/service-page-writer' },
        { name: 'FAQ Generator', path: '/landing/faq-generator' },
        { name: 'Features Page Writer', path: '/landing/features-page-writer' }
      ]
    }
  };

  const handleDropdownEnter = (dropdownName) => {
    setOpenDropdown(dropdownName);
  };

  const handleDropdownLeave = () => {
    setOpenDropdown(null);
  };

  const toggleMobileDropdown = (category) => {
    setMobileDropdown(prevState => {
      // If clicking on Use Cases
      if (category === 'use-cases') {
        if (prevState === null || (Array.isArray(prevState) && !prevState.includes('use-cases'))) {
          return ['use-cases'];
        }
        return null;
      }
      
      // If clicking on a category
      if (Array.isArray(prevState)) {
        // If category is already open, close it but keep use-cases open
        if (prevState.includes(category)) {
          return ['use-cases'];
        }
        // Otherwise open this category alongside use-cases
        return ['use-cases', category];
      }
      
      // If nothing was open, open both use-cases and this category
      return ['use-cases', category];
    });
  };

  const isOpen = (section) => {
    return Array.isArray(mobileDropdown) ? mobileDropdown.includes(section) : mobileDropdown === section;
  };

  return (
    <>
      <nav className="bg-white shadow-sm border-b relative z-30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-2">
              <div className="w-8 h-8 rounded-lg bg-purple-600 flex items-center justify-center">
                <span className="text-white font-bold text-xl">C</span>
              </div>
              <div className="hidden sm:flex flex-col">
                <span className="text-lg font-bold text-gray-900">The Content GPT</span>
                <span className="text-xs text-gray-600">AI-Powered Content Solutions</span>
              </div>
            </Link>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
              >
                <Menu className="h-6 w-6" />
              </button>
            </div>

            {/* Desktop Navigation Links */}
            <div className="hidden md:flex items-center space-x-8">
              {/* Use Cases Dropdown */}
              <div className="relative">
                <button
                  onMouseEnter={() => handleDropdownEnter('use-cases')}
                  className="flex items-center space-x-1 px-3 py-2 text-gray-600 hover:text-purple-600"
                >
                  <span>Use Cases</span>
                  <ChevronDown className="h-4 w-4" />
                </button>

                {/* Mega Menu Dropdown */}
                {openDropdown === 'use-cases' && (
                  <div
                    onMouseEnter={() => handleDropdownEnter('use-cases')}
                    onMouseLeave={handleDropdownLeave}
                    className="absolute top-full left-0 w-[600px] bg-white shadow-lg rounded-lg mt-1 p-6 grid grid-cols-2 gap-8 z-50"
                  >
                    {Object.entries(menuCategories).map(([category, { icon: Icon, tools }]) => (
                      <div key={category}>
                        <div className="flex items-center space-x-2 mb-4">
                          <Icon className="h-5 w-5 text-purple-600" />
                          <h3 className="font-semibold text-gray-900">{category}</h3>
                        </div>
                        <ul className="space-y-2">
                          {tools.map((tool) => (
                            <li key={tool.name}>
                              <Link
                                to={tool.path}
                                className="text-gray-600 hover:text-purple-600 block py-1"
                              >
                                {tool.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Other Navigation Links */}
              <Link
                to="/landing/human-writer"
                className="px-3 py-2 text-gray-600 hover:text-purple-600"
              >
                Human Rewriter
              </Link>

              <Link
                to="/pricing"
                className="px-3 py-2 text-gray-600 hover:text-purple-600"
              >
                Pricing
              </Link>

              <Link
                to="/blog"
                className="px-3 py-2 text-gray-600 hover:text-purple-600"
              >
                Blog
              </Link>
            </div>

            {/* Auth Buttons */}
            <div className="hidden md:flex items-center space-x-4">
              <Link
                to="/login"
                className="text-gray-600 hover:text-purple-600"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation Menu - Fixed Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
          <div className="fixed inset-y-0 inset-x-0 bg-white shadow-xl overflow-y-auto z-50">
            {/* Close Button */}
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-500"
            >
              <X className="h-6 w-6" />
            </button>

            <div className="p-4 pt-16 space-y-4">
              {/* Mobile Use Cases Dropdown */}
              <div>
                <button
                  onClick={() => toggleMobileDropdown('use-cases')}
                  className="flex items-center justify-between w-full px-3 py-2 text-gray-600"
                >
                  <span className="font-medium">Use Cases</span>
                  <ChevronDown 
                    className={`h-4 w-4 transform transition-transform ${
                      isOpen('use-cases') ? 'rotate-180' : ''
                    }`}
                  />
                </button>

                {isOpen('use-cases') && (
                  <div className="mt-2 space-y-2">
                    {Object.entries(menuCategories).map(([category, { icon: Icon, tools }]) => (
                      <div key={category} className="border-b border-gray-100 pb-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleMobileDropdown(category);
                          }}
                          className="flex items-center justify-between w-full px-3 py-2 text-gray-600"
                        >
                          <div className="flex items-center space-x-2">
                            <Icon className="h-5 w-5 text-purple-600" />
                            <span>{category}</span>
                          </div>
                          <ChevronDown 
                            className={`h-4 w-4 transform transition-transform ${
                              isOpen(category) ? 'rotate-180' : ''
                            }`}
                          />
                        </button>

                        <div className={`pl-10 pr-4 space-y-2 mt-2 overflow-hidden transition-all duration-200 ${
                          isOpen(category) ? 'max-h-96' : 'max-h-0'
                        }`}>
                          {tools.map((tool) => (
                            <Link
                              key={tool.name}
                              to={tool.path}
                              className="block text-gray-600 hover:text-purple-600 py-1"
                              onClick={() => setIsMobileMenuOpen(false)}
                            >
                              {tool.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Mobile Other Links */}
              <Link
                to="/tools/human-writer"
                className="block px-3 py-2 text-gray-600 hover:text-purple-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Human Rewriter
              </Link>

              <Link
                to="/pricing"
                className="block px-3 py-2 text-gray-600 hover:text-purple-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Pricing
              </Link>

              <Link
                to="/blog"
                className="block px-3 py-2 text-gray-600 hover:text-purple-600"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Blog
              </Link>

              {/* Mobile Auth Links */}
              <div className="pt-4 space-y-2">
                <Link
                  to="/login"
                  className="block w-full text-center px-4 py-2 text-gray-600 hover:text-purple-600"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="block w-full text-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PublicNavbar;