import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Wand2, 
  FileText, 
  Target, 
  Layout,
  MessageCircle,
  ArrowRight,
  Star,
  ChevronDown,
  Clock,
  Search,
  Sparkles,
  Zap,
  Check
} from 'lucide-react';

const BlogGeneratorLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const features = [
    {
      title: "SEO-Optimized Content",
      description: "Generate blog posts that rank high on search engines with strategic keyword placement and optimized structure",
      icon: Target
    },
    {
      title: "Perfect Structure",
      description: "Create well-organized posts with engaging introductions, clear headings, and compelling conclusions",
      icon: Layout
    },
    {
      title: "Multiple Topics",
      description: "Generate content for any niche, from tech and marketing to lifestyle and health",
      icon: MessageCircle
    },
    {
      title: "Fast Creation",
      description: "Create high-quality 2000+ word blog posts in under 2 minutes",
      icon: Zap
    }
  ];

  const exampleTopics = [
    "10 Essential SEO Strategies for 2024",
    "The Ultimate Guide to Content Marketing",
    "How to Start a Successful Blog",
    "5 Ways to Improve Your Writing Skills",
    "The Future of Digital Marketing",
    "Understanding Artificial Intelligence"
  ];

  const testimonials = [
    {
      name: "David Wilson",
      role: "Content Marketing Manager",
      company: "Digital Growth",
      content: "This tool has revolutionized our content creation process. We've increased our blog output by 300% while maintaining high quality."
    },
    {
      name: "Lisa Chen",
      role: "Professional Blogger",
      company: "TechInsights",
      content: "The SEO optimization is incredible. My posts are ranking higher than ever, and the content quality is consistently excellent."
    },
    {
      name: "Marcus Thompson",
      role: "Digital Entrepreneur",
      company: "StartupSuccess",
      content: "I was skeptical about AI-generated content, but this tool produces blogs that are engaging and truly valuable to my readers."
    }
  ];

  const faqs = [
    {
      question: "How does the Blog Post Generator work?",
      answer: "Our AI-powered generator takes your topic or keywords and creates a complete, well-structured blog post. It researches current information, organizes the content with proper headings, and writes engaging content that's both informative and SEO-optimized."
    },
    {
      question: "What length of blog posts can it create?",
      answer: "The tool can generate blog posts ranging from 500 to 3000+ words. You can specify your desired length, and the AI will create comprehensive content that matches your requirements while maintaining quality and relevance."
    },
    {
      question: "Is the content unique and plagiarism-free?",
      answer: "Yes, absolutely! Every blog post generated is 100% unique and original. Our AI creates fresh content for each request, and you can run it through any plagiarism checker for verification."
    },
    {
      question: "Can I edit the generated content?",
      answer: "Yes, you have full control over the generated content. You can edit, modify, or enhance any part of the blog post to match your voice and style perfectly."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-bold mb-6">
            <span className="block">Create Engaging Blog Posts</span>
            <span className="block text-purple-600">In Minutes, Not Hours</span>
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
            Transform your ideas into SEO-optimized, engaging blog posts with our advanced AI technology. Save time while creating content that ranks.
          </p>
          <div className="flex justify-center gap-4">
            <Link 
              to="/register"
              className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
            >
              Start Writing Free
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <a 
              href="#features"
              className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
            >
              See Features
            </a>
          </div>

          {/* Stats */}
          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-3xl mx-auto">
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="text-3xl font-bold text-purple-600 mb-2">2 Minutes</div>
              <div className="text-gray-600">Average Creation Time</div>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="text-3xl font-bold text-purple-600 mb-2">100%</div>
              <div className="text-gray-600">Unique Content</div>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="text-3xl font-bold text-purple-600 mb-2">3000+</div>
              <div className="text-gray-600">Words Per Article</div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div id="features" className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Powerful Features for Better Content</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
              <feature.icon className="h-12 w-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Example Topics */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Example Blog Topics</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {exampleTopics.map((topic, index) => (
              <div key={index} className="bg-purple-50 p-6 rounded-xl">
                <FileText className="h-6 w-6 text-purple-600 mb-2" />
                <p className="font-medium">{topic}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* How It Works */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Search className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="font-semibold mb-2">1. Choose Your Topic</h3>
              <p className="text-gray-600">Enter your topic or keywords and set your preferences</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Sparkles className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="font-semibold mb-2">2. Generate Content</h3>
              <p className="text-gray-600">Our AI creates a complete, SEO-optimized blog post</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Check className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="font-semibold mb-2">3. Edit & Publish</h3>
              <p className="text-gray-600">Review, edit if needed, and publish your blog post</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">What Our Users Say</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-xl">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Create Amazing Blog Content?</h2>
          <p className="text-xl mb-8">Join thousands of content creators who trust our AI blog writer</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Creating Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogGeneratorLanding;