import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import apiService from '../services/apiService';
import { 
  Calendar,
  Clock, 
  Tag,
  ChevronUp,
  Facebook,
  Twitter,
  Linkedin,
  Link as LinkIcon,
  ArrowRight
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [tableOfContents, setTableOfContents] = useState([]);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        setError('');
        const data = await apiService.fetch(`/api/blog/posts/${slug}`);
        setPost(data);
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('Failed to load post. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchPost();
    }
  }, [slug]);

  // Generate table of contents from markdown content
  useEffect(() => {
    if (post?.content) {
      const headings = post.content.match(/#{1,3} .+/g) || [];
      const toc = headings.map(heading => {
        const level = (heading.match(/^#+/) || [''])[0].length;
        const title = heading.replace(/^#+\s*/, '');
        const id = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        return { level, title, id };
      });
      setTableOfContents(toc);
    }
  }, [post?.content]);

  // Track active section on scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll('h1, h2, h3').forEach(
      heading => observer.observe(heading)
    );

    return () => observer.disconnect();
  }, [post]);

  // src/pages/BlogPost.js - Update the fetch logic

const fetchPost = async () => {
  try {
    setLoading(true);
    setError('');
    
    const response = await fetch(`${apiService.getUrl('/api/blog/posts')}/${slug}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      mode: 'cors'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch post');
    }

    const data = await response.json();
    if (!data) {
      throw new Error('Post not found');
    }

    setPost(data);
  } catch (err) {
    console.error('Error fetching post:', err);
    setError('Failed to load post. Please try again.');
  } finally {
    setLoading(false);
  }
};

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const calculateReadTime = (content) => {
    const wordsPerMinute = 200;
    const words = content.split(/\s+/).length;
    return Math.ceil(words / wordsPerMinute);
  };

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 border-purple-200 border-t-purple-600 rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8">
        <div className="text-center text-red-600">{error}</div>
      </div>
    );
  }

  if (!post) return null;

  // Generate share URLs
  const currentUrl = window.location.href;
  const shareUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(post.title)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white border-b">
        <div className="max-w-4xl mx-auto px-4 py-12">
          {/* Category & Title */}
          <div className="text-center mb-8">
            <div className="text-purple-600 font-medium mb-4">
              {post.tags[0]}
            </div>
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              {post.title}
            </h1>

            {/* Excerpt */}
            {post.excerpt && (
              <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-6">
                {post.excerpt}
              </p>
            )}
            
            {/* Meta Information */}
            <div className="flex items-center justify-center text-gray-600 space-x-4">
              <div className="flex items-center">
                <Calendar className="h-5 w-5 mr-2" />
                <span>{formatDate(post.publishDate || post.createdAt)}</span>
              </div>
              <span>•</span>
              <div className="flex items-center">
                <Clock className="h-5 w-5 mr-2" />
                <span>{calculateReadTime(post.content)} min read</span>
              </div>
            </div>
          </div>
          
          {/* Featured Image */}
          {post.featuredImage && (
            <div className="rounded-xl overflow-hidden shadow-lg">
              <img
                src={post.featuredImage}
                alt={post.title}
                className="w-full h-auto"
              />
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Table of Contents - Desktop */}
          <div className="hidden lg:block">
            <div className="sticky top-8">
              <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="font-bold text-gray-900 mb-4">
                  Table of Contents
                </h3>
                <nav className="space-y-2">
                  {tableOfContents.map(({ title, id, level }) => (
                    <button
                      key={id}
                      onClick={() => scrollToSection(id)}
                      className={`block text-left w-full px-2 py-1 text-sm rounded transition-colors ${
                        activeSection === id
                          ? 'text-purple-600 bg-purple-50'
                          : 'text-gray-600 hover:text-purple-600'
                      }`}
                      style={{ marginLeft: `${(level - 1) * 1}rem` }}
                    >
                      {title}
                    </button>
                  ))}
                </nav>
              </div>

              {/* Social Share */}
              <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
                <h3 className="font-bold text-gray-900 mb-4">Share</h3>
                <div className="flex space-x-4">
                  <a
                    href={shareUrls.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-blue-500 transition-colors"
                  >
                    <Facebook className="h-5 w-5" />
                  </a>
                  <a
                    href={shareUrls.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-blue-400 transition-colors"
                  >
                    <Twitter className="h-5 w-5" />
                  </a>
                  <a
                    href={shareUrls.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-blue-700 transition-colors"
                  >
                    <Linkedin className="h-5 w-5" />
                  </a>
                  <button 
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      alert('Link copied to clipboard!');
                    }}
                    className="text-gray-400 hover:text-gray-600 transition-colors"
                  >
                    <LinkIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:col-span-3">
            <article className="bg-white rounded-xl shadow-sm p-8">
              <div className="prose prose-lg max-w-none prose-headings:scroll-mt-20">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h1: ({node, ...props}) => (
                      <h1 
                        id={props.children.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-')}
                        className="text-3xl font-bold mt-8 mb-4" 
                        {...props} 
                      />
                    ),
                    h2: ({node, ...props}) => (
                      <h2 
                        id={props.children.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-')}
                        className="text-2xl font-bold mt-6 mb-3" 
                        {...props} 
                      />
                    ),
                    h3: ({node, ...props}) => (
                      <h3 
                        id={props.children.toString().toLowerCase().replace(/[^a-z0-9]+/g, '-')}
                        className="text-xl font-bold mt-4 mb-2" 
                        {...props} 
                      />
                    ),
                    ul: ({node, ...props}) => (
                      <ul className="list-disc pl-6 my-4 space-y-2" {...props} />
                    ),
                    ol: ({node, ...props}) => (
                      <ol className="list-decimal pl-6 my-4 space-y-2" {...props} />
                    ),
                    li: ({node, ...props}) => (
                      <li className="text-gray-700" {...props} />
                    ),
                    p: ({node, ...props}) => (
                      <p className="text-gray-700 leading-relaxed mb-4" {...props} />
                    ),
                    blockquote: ({node, ...props}) => (
                      <blockquote className="border-l-4 border-purple-500 pl-4 my-4 italic text-gray-700" {...props} />
                    ),
                    code: ({node, ...props}) => (
                      <code className="bg-gray-100 rounded px-1 py-0.5 text-sm font-mono" {...props} />
                    ),
                    pre: ({node, ...props}) => (
                      <pre className="bg-gray-900 text-white rounded-lg p-4 my-4 overflow-x-auto" {...props} />
                    ),
                  }}
                >
                  {post.content}
                </ReactMarkdown>
              </div>

              {/* Tags */}
              <div className="mt-8 pt-8 border-t">
                <div className="flex flex-wrap gap-2">
                  {post.tags.map((tag) => (
                    <span
                      key={tag}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800"
                    >
                      <Tag className="h-4 w-4 mr-1" />
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </article>

            {/* CTA Section */}
            <div className="mt-8 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl shadow-lg overflow-hidden">
              <div className="p-8 md:p-12">
                <div className="grid md:grid-cols-2 gap-8 items-center">
                  <div>
                    <h2 className="text-2xl font-bold text-white mb-4">
                      Ready to Create Amazing Content?
                    </h2>
                    <p className="text-purple-100 mb-6">
                      Get started with our AI-powered content creation tools and transform your ideas into engaging content. Sign up now and receive 1000 free points!
                    </p>
                    <Link
                      to="/register"
                      className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-purple-50 transition-colors"
                    >
                      Get 1000 Free Points
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;