import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Shield, 
  Check, 
  ArrowRight, 
  Star,
  Zap,
  Lock,
  FileText,
  RefreshCcw,
  ChevronDown,
  AlertCircle
} from 'lucide-react';

const AIDetectionBypassLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const features = [
    {
      title: "100% Undetectable",
      description: "Bypass all major AI detection tools including GPTZero, Originality.ai, and more",
      icon: Shield
    },
    {
      title: "Instant Results",
      description: "Transform your content in seconds while maintaining its original meaning",
      icon: Zap
    },
    {
      title: "SEO Friendly",
      description: "Maintain or improve your content's search engine optimization",
      icon: FileText
    },
    {
      title: "Content Protection",
      description: "Keep your content safe from AI detection and potential penalties",
      icon: Lock
    }
  ];

  const testimonials = [
    {
      name: "Sarah Johnson",
      role: "Content Manager",
      company: "TechStartup",
      content: "This tool has been a game-changer for our content team. We can now confidently publish AI-assisted content without worrying about detection."
    },
    {
      name: "Michael Chen",
      role: "SEO Specialist",
      company: "Digital Marketing Agency",
      content: "Not only does it bypass AI detection, but it actually improves the content's readability and SEO performance. Absolutely worth it!"
    },
    {
      name: "Emily Rodriguez",
      role: "Freelance Writer",
      company: "Self-employed",
      content: "I use AI to draft my articles, but was worried about detection. This tool gives me peace of mind and helps maintain my professional reputation."
    }
  ];

  const faqs = [
    {
      question: "How does the AI Detection Bypass work?",
      answer: "Our advanced algorithm analyzes your content and restructures it to appear more human-like while maintaining your original message. It adjusts sentence patterns, word choices, and content structure to ensure it passes all major AI detection tools."
    },
    {
      question: "Will it change the meaning of my content?",
      answer: "No, our tool preserves the original meaning and key messages of your content while only modifying the writing style and structure to appear more human-like."
    },
    {
      question: "Which AI detection tools can it bypass?",
      answer: "Our tool successfully bypasses all major AI detection systems including GPTZero, Originality.ai, Turnitin, Content at Scale, Winston AI, and more."
    },
    {
      question: "How many words can I process at once?",
      answer: "You can process up to 25,000 words in a single submission. For larger projects, you can process your content in multiple parts."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Premium Feature Banner */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-2">
            <Lock className="h-4 w-4" />
            <span>Premium Feature: 100% Undetectable AI Content</span>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Make Your AI Content</span>
          <span className="block text-purple-600">100% Undetectable</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Transform AI-generated content into natural, human-like writing that bypasses all detection tools while maintaining your message.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Try It Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#how-it-works"
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            Learn More
          </a>
        </div>

        {/* Trust Badges */}
        <div className="mt-12 flex justify-center items-center space-x-8">
          <div className="flex items-center">
            <Shield className="h-5 w-5 text-green-500 mr-2" />
            <span>100% Undetectable</span>
          </div>
          <div className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <span>Human-Like Content</span>
          </div>
          <div className="flex items-center">
            <RefreshCcw className="h-5 w-5 text-green-500 mr-2" />
            <span>Instant Results</span>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
              <feature.icon className="h-12 w-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* How It Works */}
      <div id="how-it-works" className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">1</span>
              </div>
              <h3 className="font-semibold mb-2">Paste Your Content</h3>
              <p className="text-gray-600">Simply paste your AI-generated content into our editor</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">2</span>
              </div>
              <h3 className="font-semibold mb-2">Process Content</h3>
              <p className="text-gray-600">Our AI analyzes and transforms your content to bypass detection</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">3</span>
              </div>
              <h3 className="font-semibold mb-2">Get Undetectable Content</h3>
              <p className="text-gray-600">Download your human-like content ready for publication</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">What Our Users Say</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="bg-white py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Make Your Content Undetectable?</h2>
          <p className="text-xl mb-8">Join thousands of content creators who trust our AI detection bypass tool</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Get Started Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AIDetectionBypassLanding;