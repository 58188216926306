import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRight,
  BookOpen,
  Type,
  Edit3,
  Layers,
  MessageCircle,
  Users,
  Compass,
  ChevronDown,
  CheckCircle,
  BookMarked,
  Feather,
  AlignLeft,
  Scissors,
  PenTool,
  Smile,
  Zap,
  Eye
} from 'lucide-react';

const ReadabilityImproverLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [activeScore, setActiveScore] = useState('before');
  const [selectedExample, setSelectedExample] = useState(0);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const readabilityScores = {
    before: {
      fleschScore: 43,
      gradingLevel: "College Level",
      complexity: "High",
      engagement: "Low"
    },
    after: {
      fleschScore: 82,
      gradingLevel: "6th Grade",
      complexity: "Optimal",
      engagement: "High"
    }
  };

  const beforeAfterExamples = [
    {
      title: "Academic Text",
      before: "The utilization of complex syntactic structures in academic discourse can obfuscate the intended message and impede comprehension among readers.",
      after: "Using complex sentences in academic writing can make it hard for readers to understand the main message."
    },
    {
      title: "Technical Documentation",
      before: "The implementation of the algorithm necessitates the initialization of multiple concurrent processes to facilitate optimal performance.",
      after: "To run the algorithm well, you need to start several processes at the same time."
    },
    {
      title: "Marketing Copy",
      before: "Our revolutionary product leverages cutting-edge technological innovations to maximize operational efficiency and drive unprecedented results.",
      after: "Our new product uses the latest tech to help you work faster and get better results."
    }
  ];

  const features = [
    {
      title: "Real-time Scoring",
      description: "Get instant feedback on your text's readability",
      icon: Zap
    },
    {
      title: "Style Analysis",
      description: "Check tone, voice, and writing consistency",
      icon: PenTool
    },
    {
      title: "Sentence Structure",
      description: "Improve flow and reduce complexity",
      icon: AlignLeft
    },
    {
      title: "Vocabulary Enhancement",
      description: "Suggest clearer alternatives for complex words",
      icon: BookMarked
    },
    {
      title: "Audience Targeting",
      description: "Adapt content for your specific readers",
      icon: Users
    },
    {
      title: "Visual Clarity",
      description: "Optimize formatting and paragraph structure",
      icon: Eye
    }
  ];

  const readabilityMetrics = [
    {
      name: "Flesch Reading Ease",
      description: "Measures how easy your text is to understand",
      icon: BookOpen
    },
    {
      name: "Sentence Length",
      description: "Analyzes and optimizes sentence structure",
      icon: Type
    },
    {
      name: "Word Choice",
      description: "Suggests simpler alternatives for complex words",
      icon: Edit3
    },
    {
      name: "Text Coherence",
      description: "Ensures smooth flow between paragraphs",
      icon: Layers
    }
  ];

  const useCases = [
    {
      title: "Content Writers",
      description: "Create clear, engaging content that connects with readers",
      improvements: ["28% better engagement", "40% longer read times"]
    },
    {
      title: "Technical Writers",
      description: "Simplify complex information for broader understanding",
      improvements: ["45% improved comprehension", "33% faster documentation"]
    },
    {
      title: "Marketing Teams",
      description: "Craft compelling copy that converts",
      improvements: ["52% higher conversion rates", "37% lower bounce rates"]
    }
  ];

  const faqs = [
    {
      question: "How does the readability score work?",
      answer: "Our tool uses multiple readability formulas including Flesch-Kincaid, SMOG, and Gunning Fog Index to provide comprehensive readability analysis. It considers factors like sentence length, word complexity, and paragraph structure."
    },
    {
      question: "Can I customize the target reading level?",
      answer: "Yes! You can set your desired reading level based on your audience, from elementary to professional level. The tool will provide suggestions to match your target readability score."
    },
    {
      question: "Will it maintain my writing style?",
      answer: "Our AI is designed to preserve your unique voice while improving clarity. It focuses on structural improvements rather than changing your personal writing style."
    },
    {
      question: "What languages are supported?",
      answer: "Currently, we support English, Spanish, French, and German, with more languages being added regularly. Each language has its own specialized readability metrics."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Transform Your Writing with</span>
          <span className="block text-blue-600">AI-Powered Clarity</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Make your content clear, engaging, and accessible to any audience with our advanced readability improver.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transition-all flex items-center"
          >
            Improve Your Writing
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#demo"
            className="border border-blue-600 text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-all"
          >
            Try Demo
          </a>
        </div>
      </div>

      {/* Interactive Before/After Demo */}
      <div id="demo" className="max-w-6xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">See the Difference</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Before</h3>
              <span className="text-red-500 text-sm">Needs Improvement</span>
            </div>
            <p className="text-gray-600 mb-4">{beforeAfterExamples[selectedExample].before}</p>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-red-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Readability Score</span>
                <span className="text-xl font-bold text-red-600">{readabilityScores.before.fleschScore}</span>
              </div>
              <div className="bg-red-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Grade Level</span>
                <span className="text-lg font-bold text-red-600">{readabilityScores.before.gradingLevel}</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">After</h3>
              <span className="text-green-500 text-sm">Optimized</span>
            </div>
            <p className="text-gray-600 mb-4">{beforeAfterExamples[selectedExample].after}</p>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-green-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Readability Score</span>
                <span className="text-xl font-bold text-green-600">{readabilityScores.after.fleschScore}</span>
              </div>
              <div className="bg-green-50 p-3 rounded-lg">
                <span className="block text-sm text-gray-600">Grade Level</span>
                <span className="text-lg font-bold text-green-600">{readabilityScores.after.gradingLevel}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-4 mt-8">
          {beforeAfterExamples.map((example, index) => (
            <button
              key={index}
              onClick={() => setSelectedExample(index)}
              className={`px-4 py-2 rounded-lg transition-all ${
                selectedExample === index
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {example.title}
            </button>
          ))}
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Smart Writing Analysis</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <feature.icon className="h-12 w-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Reading Level Visualizer */}
      <div className="bg-gradient-to-r from-blue-100 to-indigo-50 py-16">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Reading Level Analysis</h2>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="space-y-6">
              {readabilityMetrics.map((metric, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <metric.icon className="h-8 w-8 text-blue-600" />
                  <div>
                    <h3 className="font-semibold">{metric.name}</h3>
                    <p className="text-gray-600 text-sm">{metric.description}</p>
                  </div>
                  <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-blue-600 rounded-full"
                      style={{ width: `${Math.random() * 40 + 60}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Use Cases */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Who Benefits?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {useCases.map((useCase, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                <h3 className="text-xl font-semibold mb-4">{useCase.title}</h3>
                <p className="text-gray-600 mb-6">{useCase.description}</p>
                <ul className="space-y-2">
                  {useCase.improvements.map((improvement, i) => (
                    <li key={i} className="flex items-center text-green-600">
                      <CheckCircle className="h-5 w-5 mr-2" />
                      {improvement}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="bg-white py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Common Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Improve Your Writing?</h2>
          <p className="text-xl mb-8">Join thousands of writers creating clearer, more engaging content</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-blue-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Writing Better
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReadabilityImproverLanding;