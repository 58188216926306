import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Mail,
  ArrowRight,
  Star,
  Zap,
  Target,
  BarChart,
  Layout,
  Users,
  Globe,
  ChevronDown,
  MessageCircle,
  Check
} from 'lucide-react';

const EmailNewsletterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const features = [
    {
      title: "Smart Templates",
      description: "Industry-specific templates that drive engagement and conversions",
      icon: Layout
    },
    {
      title: "A/B Testing Ready",
      description: "Create multiple versions to test and optimize performance",
      icon: Target
    },
    {
      title: "Engagement Analytics",
      description: "Predict email performance before sending",
      icon: BarChart
    },
    {
      title: "Personalization",
      description: "Dynamic content insertion for each subscriber",
      icon: Users
    },
    {
      title: "Multi-Language",
      description: "Create emails in 30+ languages instantly",
      icon: Globe
    },
    {
      title: "Smart Suggestions",
      description: "AI-powered content and subject line recommendations",
      icon: MessageCircle
    }
  ];

  const templates = [
    "Welcome Series",
    "Product Announcements",
    "Weekly Newsletters",
    "Customer Updates",
    "Event Invitations",
    "Sales Promotions",
    "Educational Content",
    "Customer Stories"
  ];

  const testimonials = [
    {
      name: "David Wilson",
      role: "Email Marketing Manager",
      company: "E-commerce Brand",
      content: "We've seen a 40% increase in our email open rates since using this tool. The AI-generated subject lines are incredibly effective."
    },
    {
      name: "Lisa Chen",
      role: "Newsletter Creator",
      company: "Tech Newsletter",
      content: "Creating engaging newsletters used to take hours. Now I can generate an entire week's worth of newsletters in minutes."
    },
    {
      name: "Mark Thompson",
      role: "Digital Marketing Director",
      company: "Marketing Agency",
      content: "The personalization features have transformed our email campaigns. Our clients are seeing unprecedented engagement rates."
    }
  ];

  const faqs = [
    {
      question: "How does the AI email newsletter creator work?",
      answer: "Our AI analyzes your brand voice, target audience, and campaign goals to generate engaging email content. It combines proven email marketing principles with cutting-edge AI to create newsletters that drive engagement and conversions."
    },
    {
      question: "Can I customize the templates?",
      answer: "Absolutely! All templates are fully customizable. You can modify layouts, colors, content blocks, and styling to match your brand identity perfectly."
    },
    {
      question: "What types of newsletters can I create?",
      answer: "You can create any type of newsletter including welcome series, promotional emails, product launches, company updates, educational content, and more. Our AI adapts to your specific needs."
    },
    {
      question: "Does it integrate with email marketing platforms?",
      answer: "Yes, our tool integrates seamlessly with major email marketing platforms like Mailchimp, Campaign Monitor, SendGrid, and others for easy export and sending."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">AI-Powered</span>
          <span className="block text-blue-600">Email Newsletter Creator</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Create engaging, conversion-focused email newsletters in minutes. Save time while improving open rates and click-through rates.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transition-all flex items-center"
          >
            Start Creating Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#templates"
            className="border border-blue-600 text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-all"
          >
            View Templates
          </a>
        </div>

        {/* Trust Indicators */}
        <div className="mt-12 flex justify-center items-center space-x-8">
          <div className="flex items-center">
            <Mail className="h-5 w-5 text-blue-500 mr-2" />
            <span>10M+ Emails Created</span>
          </div>
          <div className="flex items-center">
            <Users className="h-5 w-5 text-blue-500 mr-2" />
            <span>50K+ Users</span>
          </div>
          <div className="flex items-center">
            <Star className="h-5 w-5 text-blue-500 mr-2" />
            <span>4.9/5 Rating</span>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Everything You Need for Perfect Newsletters</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
              <feature.icon className="h-12 w-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Templates Section */}
      <div id="templates" className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Ready-to-Use Templates</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {templates.map((template, index) => (
              <div 
                key={index}
                className="bg-blue-50 p-6 rounded-xl text-center hover:bg-blue-100 transition-colors"
              >
                <Mail className="h-8 w-8 text-blue-600 mx-auto mb-3" />
                <h3 className="font-medium">{template}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Process Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Create in 3 Simple Steps</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-blue-600 font-bold text-xl">1</span>
              </div>
              <h3 className="font-semibold mb-2">Choose Template</h3>
              <p className="text-gray-600">Select from our library of proven templates</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-blue-600 font-bold text-xl">2</span>
              </div>
              <h3 className="font-semibold mb-2">Customize Content</h3>
              <p className="text-gray-600">Let AI generate engaging content or add your own</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-blue-600 font-bold text-xl">3</span>
              </div>
              <h3 className="font-semibold mb-2">Export & Send</h3>
              <p className="text-gray-600">Download or send directly to your email platform</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Trusted by Marketing Professionals</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-blue-50 p-6 rounded-xl">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Email Marketing?</h2>
          <p className="text-xl mb-8">Join thousands of marketers creating engaging newsletters with AI</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-blue-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Creating Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmailNewsletterLanding;