import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import RecentActivity from '../components/RecentActivity';
import apiService from '../services/apiService';
import {
  Menu,
  FileText,
  Settings,
  User,
  PlusCircle,
  LayoutDashboard,
  Image,
  BarChart,
  Layout,
  Calendar,
  Star,
  Clock,
  Wand2,
  ChevronRight,
  LogOut,
  X,
  HelpCircle,
  BookOpen,
  MessageSquare
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardStats, setDashboardStats] = useState({
    wordsGenerated: 0,
    wordsRemaining: 0,
    totalPosts: 0,
    postsThisMonth: 0,
    pointsUsed: 0,
    timeSaved: 0
  });
  const [recentActivity, setRecentActivity] = useState([]);
  const [usageData, setUsageData] = useState([]);

  // Fetch dashboard data
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
  
        // Updated endpoint paths to include /api/
        const statsResponse = await apiService.fetch('/api/dashboard/stats');
        setDashboardStats(statsResponse);
  
        // Updated endpoint path to include /api/
        const usageResponse = await apiService.fetch('/api/dashboard/analytics', {
          params: { timeRange: '7d' }
        });
        
        // Format the data for the chart
        const formattedUsageData = usageResponse.chartData.map(day => ({
          date: new Date(day.date).toLocaleDateString('en-US', { weekday: 'short' }),
          words: day.words,
          points: day.points
        }));
  
        setUsageData(formattedUsageData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Failed to load dashboard data');
        setLoading(false);
      }
    };
  
    fetchDashboardData();
  }, []);

  const quickActions = [
    {
      name: 'AI Rewriter',
      description: 'Humanize AI content',
      path: '/rewriter',
      icon: Wand2,
      color: 'purple'
    },
    {
      name: 'Human Article Writer',
      description: 'Generate human blog posts',
      path: '/human-writer',
      icon: FileText,
      color: 'blue'
    },
    {
      name: 'Templates',
      description: 'Browse content templates',
      path: '/templates',
      icon: Layout,
      color: 'green'
    },
    {
      name: 'Analytics',
      description: 'View performance metrics',
      path: '/analytics',
      icon: BarChart,
      color: 'orange'
    }
  ];

  const navigationItems = [
    {
      type: 'main',
      items: [
        {
          name: 'Dashboard',
          icon: LayoutDashboard,
          path: '/dashboard',
          current: true
        },
        {
          name: 'Templates',
          icon: Layout,
          path: '/templates',
          current: false
        },
        {
          name: 'AI Rewriter',
          icon: Wand2,
          path: '/rewriter',
          current: false
        },
        {
          name: 'Blog Writer',
          icon: FileText,
          path: '/human-writer',
          current: false
        },
        {
          name: 'Analytics',
          icon: BarChart,
          path: '/analytics',
          current: false
        },
        {
          name: 'Image Generator',
          icon: Image,
          path: '/images',
          badge: 'Soon',
          current: false
        }
      ]
    },
    {
      type: 'secondary',
      items: [
        
        {
          name: 'Support',
          icon: MessageSquare,
          path: '/support',
          current: false
        },
        {
          name: 'Settings',
          icon: Settings,
          path: '/settings',
          current: false
        }
      ]
    }
  ];

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  // Sidebar Component
  const Sidebar = () => (
    <>
      {/* Mobile Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-40 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed lg:sticky top-0 left-0 h-screen w-64 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 z-50 transform transition-transform duration-200 ease-in-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        }`}
      >
        {/* Close button - Mobile only */}
        <button
          className="lg:hidden absolute top-4 right-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          onClick={() => setIsSidebarOpen(false)}
        >
          <X className="h-6 w-6" />
        </button>

        {/* Logo */}
        <div className="px-6 py-8 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-3">
            <div className="h-8 w-8 bg-purple-600 rounded-lg flex items-center justify-center">
              <span className="text-white font-bold text-xl">C</span>
            </div>
            <span className="text-xl font-bold text-gray-900 dark:text-white">The Content GPT</span>
          </div>
        </div>

        {/* User Profile */}
        <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-3">
            <div className="h-10 w-10 rounded-full bg-purple-100 dark:bg-purple-900/50 flex items-center justify-center">
              <User className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-900 dark:text-white">{user?.name}</h3>
              <p className="text-xs text-gray-500 dark:text-gray-400">{user?.email}</p>
            </div>
          </div>
        </div>

        {/* Navigation */}
        <nav className="p-4 space-y-6">
          {navigationItems.map((group, groupIndex) => (
            <div key={groupIndex} className="space-y-1">
              {group.items.map((item, itemIndex) => (
                <Link
                  key={itemIndex}
                  to={item.path}
                  className={`flex items-center justify-between w-full px-3 py-2 text-sm font-medium rounded-lg transition-colors ${
                    item.current
                      ? 'bg-purple-50 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400'
                      : 'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                  }`}
                >
                  <div className="flex items-center">
                    <item.icon className="h-5 w-5 mr-3" />
                    {item.name}
                  </div>
                  {item.badge && (
                    <span className="px-2 py-1 text-xs font-medium bg-purple-100 dark:bg-purple-900 text-purple-600 dark:text-purple-400 rounded-full">
                      {item.badge}
                    </span>
                  )}
                </Link>
              ))}
            </div>
          ))}
        </nav>

        {/* Logout Button */}
        <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={handleLogout}
            className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-lg transition-colors"
          >
            <LogOut className="h-5 w-5 mr-2" />
            Sign Out
          </button>
        </div>
      </div>
    </>
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-purple-200 dark:border-purple-900 border-t-purple-600 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-red-600 dark:text-red-400">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 min-w-0">
        {/* Mobile Header */}
        <div className="lg:hidden flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="p-2 rounded-lg text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <Menu className="h-6 w-6" />
          </button>
          <div className="flex items-center space-x-3">
            <div className="h-8 w-8 bg-purple-600 rounded-lg flex items-center justify-center">
              <span className="text-white font-bold text-xl">C</span>
            </div>
          </div>
        </div>

        {/* Dashboard Content */}
        <div className="p-8">
          {/* Welcome Section */}
          <div className="mb-8">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
              Welcome back, {user?.name}!
            </h1>
            <p className="text-gray-600 dark:text-gray-400">Here's what's happening with your content</p>
          </div>

          {/* Quick Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {/* Words Generated */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="w-12 h-12 bg-purple-100 dark:bg-purple-900/50 rounded-lg flex items-center justify-center">
                  <FileText className="h-6 w-6 text-purple-600 dark:text-purple-400" />
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400">Words</span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
                {dashboardStats.wordsGenerated.toLocaleString()}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">Words generated</p>
              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                {dashboardStats.wordsRemaining.toLocaleString()} points remaining
              </div>
            </div>

            {/* Posts Stats */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="w-12 h-12 bg-blue-100 dark:bg-blue-900/50 rounded-lg flex items-center justify-center">
                  <Calendar className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400">Images</span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
                {dashboardStats.totalPosts}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">Total Images</p>
              <div className="mt-2 text-sm text-green-600 dark:text-green-400">
                +{dashboardStats.postsThisMonth} this month
              </div>
            </div>

            {/* Points Stats */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="w-12 h-12 bg-green-100 dark:bg-green-900/50 rounded-lg flex items-center justify-center">
                  <Star className="h-6 w-6 text-green-600 dark:text-green-400" />
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400">Points</span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
                {dashboardStats.wordsRemaining}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">Available points</p>
              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                {dashboardStats.pointsUsed} points used
              </div>
            </div>

            {/* Time Saved Stats */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="w-12 h-12 bg-orange-100 dark:bg-orange-900/50 rounded-lg flex items-center justify-center">
                  <Clock className="h-6 w-6 text-orange-600 dark:text-orange-400" />
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400">Time Saved</span>
              </div>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
                {dashboardStats.timeSaved}hrs
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">Estimated time saved</p>
            </div>
          </div>

          {/* Quick Actions Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
            {quickActions.map((action, index) => (
              <button
                key={index}
                onClick={() => navigate(action.path)}
                className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 hover:border-purple-500 dark:hover:border-purple-500 transition-colors group"
              >
                <div className="flex items-center">
                  <div
                    className={`w-10 h-10 rounded-lg bg-${action.color}-100 dark:bg-${action.color}-900/50 flex items-center justify-center mr-3`}
                  >
                    <action.icon className={`h-5 w-5 text-${action.color}-600 dark:text-${action.color}-400`} />
                  </div>
                  <div className="text-left">
                    <div className="font-medium text-gray-900 dark:text-white">{action.name}</div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">{action.description}</div>
                  </div>
                </div>
                <ChevronRight className="h-5 w-5 text-gray-400 group-hover:text-purple-500 transition-colors" />
              </button>
            ))}
          </div>

          {/* Usage Chart */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 p-6 mb-8">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">Usage Overview</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={usageData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis
                    dataKey="date"
                    stroke="#9CA3AF"
                    style={{ fontSize: '12px' }}
                  />
                  <YAxis
                    stroke="#9CA3AF"
                    style={{ fontSize: '12px' }}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: 'none',
                      borderRadius: '8px',
                      color: '#fff'
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="words"
                    stroke="#8B5CF6"
                    name="Words Generated"
                    strokeWidth={2}
                  />
                  <Line
                    type="monotone"
                    dataKey="points"
                    stroke="#60A5FA"
                    name="Points Used"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Recent Activity */}
          <RecentActivity />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;