// src/services/apiService.js

const getApiUrl = () => {
  const apiUrl = process.env.REACT_APP_API_URL || 
    (process.env.NODE_ENV === 'production'
      ? 'https://api.thecontentgpt.com'
      : 'http://localhost:5001');
      
  // Remove trailing slash if present
  return apiUrl.replace(/\/$/, '');
};

const apiService = {
  baseUrl: getApiUrl(),
  
  getUrl: (endpoint) => {
    const base = apiService.baseUrl;
    // Ensure endpoint starts with /api/ unless it already does
    const apiEndpoint = endpoint.startsWith('/api/') ? endpoint : `/api${endpoint}`;
    return `${base}${apiEndpoint}`;
  },

  getHeaders: () => {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    
    const token = localStorage.getItem('token');
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    
    return headers;
  },

  fetch: async (endpoint, options = {}) => {
    try {
      const url = apiService.getUrl(endpoint);
      const headers = apiService.getHeaders();
      
      const config = {
        ...options,
        headers,
        mode: 'cors',
        credentials: 'include'
      };

      if (['POST', 'PUT', 'PATCH'].includes(options.method)) {
        if (typeof options.body === 'string') {
          config.body = options.body;
        } else if (options.body) {
          config.body = JSON.stringify(options.body);
        }
      }

      const response = await fetch(url, config);

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `Request failed with status ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error(`API request failed for ${endpoint}:`, error);
      throw error;
    }
  }
};

export default apiService;