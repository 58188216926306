import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, Edit3, AlertCircle, Layout, ArrowLeft } from 'lucide-react';
import AdminDashboard from './AdminDashboard';
import Editor from '../pages/Editor';  // Updated this line
import { useAuth } from '../context/AuthContext';
import apiService from '../services/apiService';

const AdminPortal = () => {
  const [activeView, setActiveView] = useState('dashboard');
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(apiService.getUrl('/api/admin/check'), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        const data = await response.json();
        
        if (!data.isAdmin) {
          navigate('/dashboard');
        }
        
        setIsAdmin(data.isAdmin);
        setLoading(false);
      } catch (error) {
        setError('You do not have admin access');
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      }
    };
  
    checkAdminStatus();
  }, [navigate]);

  const checkAdminStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('http://localhost:5001/api/admin/check', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const data = await response.json();
      
      if (!data.isAdmin) {
        navigate('/dashboard');
      }
      
      setIsAdmin(data.isAdmin);
      setLoading(false);
    } catch (error) {
      setError('You do not have admin access');
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-red-50 p-4 rounded-lg flex items-center">
          <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
          <span className="text-red-700">{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Admin Header */}
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="h-5 w-5" />
              </button>
              <h1 className="text-2xl font-bold text-gray-900">Admin Portal</h1>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setActiveView('dashboard')}
                className={`inline-flex items-center px-4 py-2 rounded-lg ${
                  activeView === 'dashboard'
                    ? 'bg-purple-100 text-purple-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Settings className="h-5 w-5 mr-2" />
                Prompt Settings
              </button>
              <button
                onClick={() => setActiveView('editor')}
                className={`inline-flex items-center px-4 py-2 rounded-lg ${
                  activeView === 'editor'
                    ? 'bg-purple-100 text-purple-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Edit3 className="h-5 w-5 mr-2" />
                Content Editor
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {activeView === 'dashboard' ? <AdminDashboard /> : <Editor />}
      </div>

      {/* Mobile Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t md:hidden">
        <div className="grid grid-cols-2 gap-4 p-4">
          <button
            onClick={() => setActiveView('dashboard')}
            className={`flex items-center justify-center p-2 rounded-lg ${
              activeView === 'dashboard'
                ? 'bg-purple-100 text-purple-700'
                : 'text-gray-600'
            }`}
          >
            <Settings className="h-5 w-5 mr-2" />
            <span>Settings</span>
          </button>
          <button
            onClick={() => setActiveView('editor')}
            className={`flex items-center justify-center p-2 rounded-lg ${
              activeView === 'editor'
                ? 'bg-purple-100 text-purple-700'
                : 'text-gray-600'
            }`}
          >
            <Edit3 className="h-5 w-5 mr-2" />
            <span>Editor</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminPortal;