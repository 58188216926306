import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ShoppingBag,
  ArrowRight,
  Star,
  Target,
  Sparkles,
  BarChart2,
  Globe,
  Tags,
  ChevronDown,
  MessageCircle,
  Languages,
  Search,
  TrendingUp,
  CheckCircle,
  Users
} from 'lucide-react';

const ProductDescriptionWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const features = [
    {
      title: "SEO-Optimized Content",
      description: "AI-generated descriptions with built-in keyword optimization",
      icon: Search
    },
    {
      title: "Multi-Platform Ready",
      description: "Tailored formats for Amazon, Shopify, and more",
      icon: Tags
    },
    {
      title: "Conversion Focused",
      description: "Compelling copy that drives sales and engagement",
      icon: TrendingUp
    },
    {
      title: "30+ Languages",
      description: "Create descriptions in multiple languages instantly",
      icon: Languages
    },
    {
      title: "Brand Voice Match",
      description: "Maintains consistent tone across all products",
      icon: MessageCircle
    },
    {
      title: "Bulk Generation",
      description: "Create multiple descriptions in one go",
      icon: Sparkles
    }
  ];

  const formats = [
    "Amazon Listings",
    "Shopify Products",
    "eBay Listings",
    "Social Media Posts",
    "Email Marketing",
    "Google Shopping",
    "Walmart Marketplace",
    "Etsy Listings"
  ];

  const testimonials = [
    {
      name: "Sarah Miller",
      role: "E-commerce Manager",
      company: "Fashion Retailer",
      content: "This tool has cut our product listing time by 75%. The descriptions are not just quick to generate, but they actually convert better than our manual ones."
    },
    {
      name: "James Chen",
      role: "Amazon Seller",
      company: "Electronics Store",
      content: "The SEO optimization is incredible. Our product visibility has improved significantly, and we're seeing better organic rankings."
    },
    {
      name: "Emily Rodriguez",
      role: "Content Manager",
      company: "Multi-brand Store",
      content: "Managing descriptions for thousands of products was impossible before. Now we can create and update listings in bulk with consistent quality."
    }
  ];

  const faqs = [
    {
      question: "How does the AI ensure accurate product descriptions?",
      answer: "Our AI analyzes your product features, target market, and brand voice to generate accurate, compelling descriptions. It combines industry best practices with your specific product details to create unique, engaging content."
    },
    {
      question: "Can I customize the output format?",
      answer: "Yes! You can choose from various pre-set formats for different platforms (Amazon, Shopify, etc.) or create your own custom templates to match your specific needs."
    },
    {
      question: "How many descriptions can I generate at once?",
      answer: "Our bulk generation feature allows you to create up to 100 descriptions simultaneously, perfect for large catalogs or seasonal updates."
    },
    {
      question: "Does it optimize for search engines?",
      answer: "Absolutely! Each description is optimized for SEO with strategic keyword placement, proper length, and engaging content that helps improve your product visibility."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">AI-Powered</span>
          <span className="block text-purple-600">Product Description Writer</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Transform your product listings with compelling, SEO-optimized descriptions in seconds. Convert browsers into buyers with professional copy.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Start Writing Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#formats"
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            View Formats
          </a>
        </div>

        {/* Trust Indicators */}
        <div className="mt-12 flex justify-center items-center space-x-8">
          <div className="flex items-center">
            <ShoppingBag className="h-5 w-5 text-purple-500 mr-2" />
            <span>1M+ Products Listed</span>
          </div>
          <div className="flex items-center">
            <Users className="h-5 w-5 text-purple-500 mr-2" />
            <span>30K+ Sellers</span>
          </div>
          <div className="flex items-center">
            <Star className="h-5 w-5 text-purple-500 mr-2" />
            <span>4.8/5 Rating</span>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Features That Drive Sales</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
              <feature.icon className="h-12 w-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Formats Section */}
      <div id="formats" className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Platform-Optimized Formats</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {formats.map((format, index) => (
              <div 
                key={index}
                className="bg-purple-50 p-6 rounded-xl text-center hover:bg-purple-100 transition-colors"
              >
                <Tags className="h-8 w-8 text-purple-600 mx-auto mb-3" />
                <h3 className="font-medium">{format}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Process Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Create Converting Descriptions in 3 Steps</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">1</span>
              </div>
              <h3 className="font-semibold mb-2">Input Product Details</h3>
              <p className="text-gray-600">Add your product features and target keywords</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">2</span>
              </div>
              <h3 className="font-semibold mb-2">Choose Format</h3>
              <p className="text-gray-600">Select your preferred platform and style</p>
            </div>
            <div className="text-center">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-purple-600 font-bold text-xl">3</span>
              </div>
              <h3 className="font-semibold mb-2">Generate & Customize</h3>
              <p className="text-gray-600">Get your optimized description instantly</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Loved by E-commerce Sellers</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-purple-50 p-6 rounded-xl">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Product Listings?</h2>
          <p className="text-xl mb-8">Join thousands of sellers creating converting product descriptions with AI</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Writing Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionWriterLanding;