import { TEMPLATE_CATEGORIES } from '../config/templateConfig';
import apiService from '../services/apiService';
import CryptoJS from 'crypto-js';


class TemplateService {
  static STORAGE_KEY = 'enc_templates';
  static OLD_STORAGE_KEY = 'promptTemplates';
  static CACHE_DURATION = 5 * 60 * 1000;

  static getEncryptionKey() {
    return process.env.REACT_APP_ENCRYPTION_KEY || 'fallback-key-123456789';
  }

  static encrypt(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.getEncryptionKey()).toString();
    } catch (error) {
      console.error('Encryption error:', error);
      return null;
    }
  }

  static decrypt(encryptedData) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, this.getEncryptionKey());
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  }

  static getCachedTemplates() {
    try {
      const encryptedData = localStorage.getItem(this.STORAGE_KEY);
      if (!encryptedData) return null;
      return this.decrypt(encryptedData);
    } catch (error) {
      console.error('Cache retrieval error:', error);
      this.clearCache();
      return null;
    }
  }

  static setCachedTemplates(templates) {
    try {
      const cacheData = {
        data: templates,
        timestamp: Date.now()
      };
      
      const encrypted = this.encrypt(cacheData);
      if (encrypted) {
        localStorage.setItem(this.STORAGE_KEY, encrypted);
        this.cleanupOldStorage();
        this.broadcastUpdate(templates);
      }
    } catch (error) {
      console.error('Cache setting error:', error);
    }
  }

  static async fetchTemplates(forceRefresh = false) {
    try {
      const cachedData = this.getCachedTemplates();
      if (!forceRefresh && cachedData && !this.isCacheExpired(cachedData.timestamp)) {
        return cachedData.data;
      }
  
      // Force fetch fresh templates
      const token = localStorage.getItem('token');
      const response = await fetch(apiService.getUrl('/api/templates'), {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-store'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch templates');
      }
  
      const templates = await response.json();
      this.setCachedTemplates(templates);
      return templates;
    } catch (error) {
      console.error('Error loading templates:', error);
      const cachedData = this.getCachedTemplates();
      return cachedData?.data || null;
    }
  }
  static clearCache() {
    localStorage.removeItem(this.STORAGE_KEY);
    this.cleanupOldStorage();
    this.broadcastUpdate(null);
  }

  static cleanupOldStorage() {
    localStorage.removeItem(this.OLD_STORAGE_KEY);
    localStorage.removeItem('templates');
    localStorage.removeItem('templateCache');
  }

  static isCacheExpired(timestamp) {
    return Date.now() - timestamp > this.CACHE_DURATION;
  }

  static broadcastUpdate(templates) {
    window.dispatchEvent(
      new CustomEvent('templateUpdate', { detail: templates })
    );
  }
// Add this method to TemplateService class
static async getTemplateByPath(path) {
  try {
    const templates = await this.fetchTemplates();
    if (!templates) return null;

    const templateId = path.split('/').pop();
    return Object.values(templates).find(template => 
      template.identifier === templateId || 
      template.name.toLowerCase().replace(/[^a-z0-9]+/g, '-') === templateId
    );
  } catch (error) {
    console.error('Error getting template by path:', error);
    return null;
  }
}



static formatTemplateFields(template) {
  if (!template?.pythonCode) return {};
  
  const fieldRegex = /{(\w+)}/g;
  const matches = template.pythonCode.match(fieldRegex) || [];
  return matches.reduce((acc, match) => {
    const fieldName = match.slice(1, -1);
    acc[fieldName] = '';
    return acc;
  }, {});
}
static getTemplate(identifier) {
  const cached = this.getCachedTemplates();
  if (!cached || this.isCacheExpired(cached.timestamp)) {
    return null;
  }
  return cached.data[identifier] || null;
}
}


export default TemplateService;