// src/utils/storageInterceptor.js

export const initStorageInterceptor = () => {
  const originalSetItem = localStorage.setItem;
  const originalGetItem = localStorage.getItem;
  
  localStorage.setItem = function(key, value) {
    if (key.includes('template') || key.includes('prompt')) {
      return; // Block storage of sensitive data
    }
    originalSetItem.apply(this, arguments);
  };

  localStorage.getItem = function(key) {
    if (key.includes('template') || key.includes('prompt')) {
      return null;
    }
    return originalGetItem.apply(this, arguments);
  };
};