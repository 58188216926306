import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import apiService from '../services/apiService';
import { 
  Search, 
  Calendar,
  Clock,
  Tag,
  ChevronLeft,
  ChevronRight,
  Loader,
  ArrowRight
} from 'lucide-react';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, [currentPage, selectedTag, searchTerm]);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${apiService.getUrl('/api/blog/posts')}?page=${currentPage}&tag=${selectedTag}&search=${searchTerm}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
  
      const data = await response.json();
      setPosts(data.posts);
      setTotalPages(data.totalPages);
      
      const tags = new Set();
      data.posts.forEach(post => {
        post.tags.forEach(tag => tags.add(tag));
      });
      setAllTags(Array.from(tags));
  
    } catch (err) {
      setError('Failed to load blog posts. Please try again later.');
      console.error('Error fetching posts:', err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (posts.length > 0) {
      console.log('Posts data:', posts);
    }
  }, [posts]);
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const calculateReadTime = (content) => {
    const wordsPerMinute = 200;
    const words = content.split(/\s+/).length;
    return Math.ceil(words / wordsPerMinute);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-purple-600 to-blue-600">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-white sm:text-5xl md:text-6xl">
              Our Blog
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-purple-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Stay updated with the latest content creation trends, tips, and success stories.
            </p>

            {/* Search Bar */}
            <div className="mt-10 max-w-xl mx-auto">
              <div className="relative">
                <input
                  type="text"
                  className="w-full px-4 py-3 pl-12 rounded-lg border-2 border-transparent focus:border-purple-300 focus:ring-0 bg-white/10 text-white placeholder-purple-200"
                  placeholder="Search articles..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                />
                <Search className="absolute left-4 top-3.5 h-5 w-5 text-purple-200" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Tags Filter */}
        <div className="flex flex-wrap gap-2 mb-8">
          <button
            onClick={() => {
              setSelectedTag('');
              setCurrentPage(1);
            }}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              !selectedTag 
                ? 'bg-purple-600 text-white' 
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
            }`}
          >
            All Posts
          </button>
          {allTags.map((tag) => (
            <button
              key={tag}
              onClick={() => {
                setSelectedTag(tag);
                setCurrentPage(1);
              }}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                selectedTag === tag
                  ? 'bg-purple-600 text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
              }`}
            >
              {tag}
            </button>
          ))}
        </div>

        {/* Error Message */}
        {error && (
          <div className="text-center py-12">
            <p className="text-red-600">{error}</p>
          </div>
        )}

        {/* Loading State */}
        {loading ? (
          <div className="text-center py-12">
            <Loader className="h-8 w-8 animate-spin mx-auto text-purple-600" />
            <p className="mt-4 text-gray-600">Loading posts...</p>
          </div>
        ) : (
          <>
            {/* Posts Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {posts.map((post) => {
  console.log('Rendering post:', post); // Add this debug log
  return (
    <article
      key={post._id}
      className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow"
    >
      <Link to={`/blog/${post.slug}`} className="block">
        {post.featuredImage && (
          <div className="relative h-48">
            <img
              src={post.featuredImage}
              alt={post.title}
              className="w-full h-full object-cover"
            />
            {post.tags[0] && (
              <span className="absolute top-4 left-4 px-3 py-1 bg-purple-600 text-white text-sm font-medium rounded-full">
                {post.tags[0]}
              </span>
            )}
          </div>
        )}
        
        <div className="p-6">
          {/* Debug output */}
          {!post.title && <p className="text-red-500">No title available for this post</p>}
          
          <h2 className="text-xl font-bold text-gray-900 mb-3 hover:text-purple-600 transition-colors">
            {post.title || 'Untitled Post'}
          </h2>

          {post.excerpt && (
            <p className="text-gray-600 mb-4 line-clamp-2">
              {post.excerpt}
            </p>
          )}

          <div className="flex items-center text-sm text-gray-500">
            <Calendar className="h-4 w-4 mr-1" />
            <span>{formatDate(post.publishDate || post.createdAt)}</span>
            <span className="mx-2">•</span>
            <Clock className="h-4 w-4 mr-1" />
            <span>{calculateReadTime(post.content)} min read</span>
          </div>

          <div className="mt-4 flex items-center text-purple-600 font-medium hover:text-purple-700">
            Read More
            <ArrowRight className="ml-2 h-4 w-4" />
          </div>
        </div>
      </Link>
    </article>
  );
})}
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="mt-12 flex justify-center">
                <nav className="flex items-center space-x-2">
                  <button
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                    className="p-2 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronLeft className="h-5 w-5" />
                  </button>
                  
                  {[...Array(totalPages)].map((_, i) => (
                    <button
                      key={i + 1}
                      onClick={() => setCurrentPage(i + 1)}
                      className={`px-4 py-2 rounded-lg ${
                        currentPage === i + 1
                          ? 'bg-purple-600 text-white'
                          : 'border border-gray-300 text-gray-700 hover:bg-gray-50'
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  
                  <button
                    onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                    disabled={currentPage === totalPages}
                    className="p-2 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            )}

            {/* No Posts Found */}
            {posts.length === 0 && !loading && (
              <div className="text-center py-12">
                <p className="text-gray-600">No posts found.</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Blog;