import React, { useState, useEffect } from 'react';
import AdminPointsManager from './AdminPointsManager';
import TemplateService from '../services/TemplateService';
import apiService from '../services/apiService';
import { 
  Settings,
  Save,
  ChevronDown,
  ChevronRight,
  Edit3,
  FileText,
  MessageCircle,
  Layout,
  Mail,
  Briefcase,
  Code,
  Presentation,
  ShoppingBag,
  BookOpen,
  Search,
  Star,
  Building,
  HelpCircle
} from 'lucide-react';

const AdminDashboard = () => {
  const [activeView, setActiveView] = useState('templates');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [loading, setLoading] = useState(false);  // Add loading state
  const [error, setError] = useState('');         // Add error state
  const [success, setSuccess] = useState('');     // Add success state
  // Helper functions for showing messages
  const showSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => setSuccess(''), 3000);
  };

  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(''), 3000);
  };
  const [templates, setTemplates] = useState({
    'ai-rewriter': {
      name: 'Content Humanizer',
      category: 'long-form',
      subcategory: 'AI Detection Bypass', 
      pointsCost: 2,
      premium: true,
      pythonCode: `client.messages.create(
        model="claude-3-haiku-20240307",
        max_tokens=4096,
        temperature=0.7,
        messages=[
          {
            "role": "user",
            "content": [
              {
                "type": "text", 
                "text": "Rewrite the following content in a completely human writing style that bypasses AI detection while maintaining the core message and meaning: {content-to-rewrite}"
              }
            ]
          }
        ]
      )`
    }
  });

  const categories = {
    'long-form': {
      icon: FileText,
      label: 'Long-Form Content',
      tools: [
        { id: 'blog-generator', name: 'Blog Post Generator', pointsCost: 1 },
        { id: 'article-writer', name: 'Article Writer', pointsCost: 1 },
        { id: 'ai-rewriter', name: 'Content Humanizer', pointsCost: 2, premium: true },
        { id: 'human-writer', name: 'Human Article Writer', pointsCost: 2, premium: true },
        { id: 'seo-writer', name: 'SEO Content Writer', pointsCost: 1 },
        { id: 'content-rewriter', name: 'Content Rewriter', pointsCost: 1 }
      ]
    },
    'marketing': {
      icon: MessageCircle,
      label: 'Marketing Content',
      tools: [
        { id: 'social-media', name: 'Social Media Post Generator', pointsCost: 1 },
        { id: 'email-newsletter', name: 'Email Newsletter Writer', pointsCost: 1 },
        { id: 'ad-copy', name: 'Ad Copy Generator', pointsCost: 1 },
        { id: 'sales-copy', name: 'Sales Copy Writer', pointsCost: 1 },
        { id: 'landing-page', name: 'Landing Page Generator', pointsCost: 1 },
        { id: 'product-desc', name: 'Product Description Writer', pointsCost: 1 }
      ]
    },
    'website': {
      icon: Layout,
      label: 'Website Content',
      tools: [
        { id: 'about-page', name: 'About Page Writer', pointsCost: 1 },
        { id: 'service-page', name: 'Service Page Creator', pointsCost: 1 },
        { id: 'features-page', name: 'Features Page Writer', pointsCost: 1 },
        { id: 'faq-generator', name: 'FAQ Generator', pointsCost: 1 },
        { id: 'privacy-policy', name: 'Privacy Policy Generator', pointsCost: 1 },
        { id: 'terms', name: 'Terms & Conditions Generator', pointsCost: 1 }
      ]
    },
    'business': {
      icon: Briefcase,
      label: 'Business Writing',
      tools: [
        { id: 'proposal', name: 'Business Proposal Writer', pointsCost: 1 },
        { id: 'bio', name: 'Professional Bio Writer', pointsCost: 1 },
        { id: 'company-profile', name: 'Company Profile Generator', pointsCost: 1 },
        { id: 'case-study', name: 'Case Study Writer', pointsCost: 1 },
        { id: 'white-paper', name: 'White Paper Generator', pointsCost: 1 },
        { id: 'press-release', name: 'Press Release Writer', pointsCost: 1 }
      ]
    },
    'technical': {
      icon: Code,
      label: 'Technical Writing',
      tools: [
        { id: 'tech-docs', name: 'Technical Documentation Writer', pointsCost: 1 },
        { id: 'api-docs', name: 'API Documentation Generator', pointsCost: 1 },
        { id: 'user-manual', name: 'User Manual Creator', pointsCost: 1 },
        { id: 'how-to', name: 'How-To Guide Generator', pointsCost: 1 },
        { id: 'tutorial', name: 'Tutorial Writer', pointsCost: 1 }
      ]
    },
    'marketing-materials': {
      icon: Presentation,
      label: 'Marketing Materials',
      tools: [
        { id: 'brochure', name: 'Brochure Content Writer', pointsCost: 1 },
        { id: 'presentation', name: 'Presentation Content Generator', pointsCost: 1 },
        { id: 'pitch-deck', name: 'Pitch Deck Content Creator', pointsCost: 1 },
        { id: 'company-newsletter', name: 'Company Newsletter Writer', pointsCost: 1 },
        { id: 'event-desc', name: 'Event Description Writer', pointsCost: 1 }
      ]
    },
    'ecommerce': {
      icon: ShoppingBag,
      label: 'E-commerce Content',
      tools: [
        { id: 'product-desc-gen', name: 'Product Description Generator', pointsCost: 1 },
        { id: 'category-content', name: 'Category Page Content', pointsCost: 1 },
        { id: 'collection-desc', name: 'Collection Description Writer', pointsCost: 1 },
        { id: 'product-review', name: 'Product Review Generator', pointsCost: 1 },
        { id: 'spec-writer', name: 'Specification Writer', pointsCost: 1 }
      ]
    },
    'academic': {
      icon: BookOpen,
      label: 'Academic Content',
      tools: [
        { id: 'research-outline', name: 'Research Paper Outline Generator', pointsCost: 1 },
        { id: 'essay-structure', name: 'Essay Structure Creator', pointsCost: 1 },
        { id: 'academic-abstract', name: 'Academic Abstract Writer', pointsCost: 1 },
        { id: 'lit-review', name: 'Literature Review Helper', pointsCost: 1 },
        { id: 'bibliography', name: 'Bibliography Formatter', pointsCost: 1 }
      ]
    },
    'seo': {
      icon: Search,
      label: 'SEO Tools',
      tools: [
        { id: 'meta-desc', name: 'Meta Description Generator', pointsCost: 1 },
        { id: 'title-tag', name: 'Title Tag Optimizer', pointsCost: 1 },
        { id: 'keyword-research', name: 'Keyword Research Helper', pointsCost: 1 },
        { id: 'seo-optimizer', name: 'SEO Content Optimizer', pointsCost: 1 },
        { id: 'schema-markup', name: 'Schema Markup Generator', pointsCost: 1 }
      ]
    },
    'specialized': {
      icon: Star,
      label: 'Specialized Content',
      tools: [
        { id: 'real-estate', name: 'Real Estate Listing Description', pointsCost: 1 },
        { id: 'job-desc', name: 'Job Description Generator', pointsCost: 1 },
        { id: 'menu-desc', name: 'Menu Description Writer', pointsCost: 1 },
        { id: 'portfolio', name: 'Portfolio Content Creator', pointsCost: 1 },
        { id: 'podcast-notes', name: 'Podcast Show Notes Generator', pointsCost: 1 }
      ]
    }
  };

  // Replace the existing useEffect for template loading with this:
// Replace your existing template loading useEffect in AdminDashboard.js with this:
useEffect(() => {
  const loadTemplates = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(apiService.getUrl('/api/admin/templates'), {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const serverTemplates = await response.json();
        const templatesObj = serverTemplates.reduce((acc, template) => {
          acc[template.identifier] = template;
          return acc;
        }, {});
        setTemplates(templatesObj);
        
        await TemplateService.setCachedTemplates(templatesObj);
      }
    } catch (error) {
      console.error('Error loading templates:', error);
    } finally {
      setLoading(false);
    }
  };

  loadTemplates();
}, []);

// Update the handleSave function
// In AdminDashboard.js, update the handleSave function:


const handleSave = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(apiService.getUrl('/api/templates'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(templates)
    });

    if (!response.ok) {
      throw new Error('Failed to save templates');
    }

    const savedTemplates = await response.json();
    await TemplateService.setCachedTemplates(savedTemplates);
    
    showSuccess('Templates saved successfully!');
  } catch (error) {
    console.error('Error saving templates:', error);
    showError(error.message);
  }
};

  const toggleCategory = (categoryId) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  const selectTemplate = (toolId) => {
    console.log('Selected template:', toolId);
    setSelectedTemplate(toolId);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Top Navigation */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <h1 className="text-xl font-bold text-gray-900">Admin Dashboard</h1>
            <div className="flex space-x-4">
              <button
                onClick={() => setActiveView('templates')}
                className={`px-4 py-2 rounded-lg ${
                  activeView === 'templates' 
                    ? 'bg-purple-100 text-purple-700' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Settings className="h-5 w-5 inline-block mr-2" />
                Templates
              </button>
              <button
                onClick={() => setActiveView('editor')}
                className={`px-4 py-2 rounded-lg ${
                  activeView === 'editor' 
                    ? 'bg-purple-100 text-purple-700' 
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Edit3 className="h-5 w-5 inline-block mr-2" />
                Editor
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {activeView === 'templates' ? (
          <div className="grid grid-cols-12 gap-8">
            {/* Categories Sidebar */}
            <div className="col-span-3 bg-white rounded-lg shadow-sm p-4">
              <div className="space-y-1">
                {Object.entries(categories).map(([categoryId, category]) => (
                  <div key={categoryId}>
                    <button
                      onClick={() => toggleCategory(categoryId)}
                      className="w-full flex items-center justify-between p-2 rounded-lg hover:bg-gray-50"
                    >
                      <div className="flex items-center">
                        <category.icon className="h-5 w-5 text-gray-400 mr-2" />
                        <span className="text-sm font-medium text-gray-700">
                          {category.label}
                        </span>
                      </div>
                      {expandedCategory === categoryId ? (
                        <ChevronDown className="h-4 w-4 text-gray-400" />
                      ) : (
                        <ChevronRight className="h-4 w-4 text-gray-400" />
                      )}
                    </button>
                    
                    {expandedCategory === categoryId && (
                      <div className="ml-7 mt-1 space-y-1">
                        {category.tools.map(tool => (
                          <button
                            key={tool.id}
                            onClick={() => selectTemplate(tool.id)}
                            className={`w-full text-left px-3 py-2 text-sm rounded-md ${
                              selectedTemplate === tool.id
                                ? 'bg-purple-50 text-purple-700'
                                : 'text-gray-600 hover:bg-gray-50'
                            }`}
                          >
                            {tool.name}
                            {tool.premium && (
                              <span className="ml-2 px-1.5 py-0.5 text-xs bg-purple-100 text-purple-700 rounded">
                                Premium
                              </span>
                            )}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Template Editor */}
            <div className="col-span-9 bg-white rounded-lg shadow-sm p-6">
            <AdminPointsManager />
  
  {selectedTemplate ? (
                <div>
                  <div className="flex justify-between items-center mb-6">
                    <div>
                      <h2 className="text-xl font-bold text-gray-900">
                        {templates[selectedTemplate]?.name || 'New Template'}
                      </h2>
                      <p className="text-sm text-gray-500 mt-1">
                        Configure the prompt template and settings
                      </p>
                    </div>
                    <button
                      onClick={handleSave}
                      className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                    >
                      <Save className="h-5 w-5 mr-2" />
                      Save Changes
                    </button>
                  </div>

                  {/* Template Configuration */}
                  <div className="space-y-6">
                    {/* Basic Settings */}
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1"> </label>
                        
                        <input
                          type="text"
                          value={templates[selectedTemplate]?.name || ''}
                          onChange={(e) => {
                            setTemplates(prev => ({
                              ...prev,
                              [selectedTemplate]: {
                                ...prev[selectedTemplate],
                                name: e.target.value
                              }
                            }));
                          }}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Points Cost (per word)
                        </label>
                        <input
                          type="number"
                          min="1"
                          max="5"
                          value={templates[selectedTemplate]?.pointsCost || 1}
                          onChange={(e) => {
                            setTemplates(prev => ({
                              ...prev,
                              [selectedTemplate]: {
                                ...prev[selectedTemplate],
                                pointsCost: parseInt(e.target.value)
                              }
                            }));
                          }}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                        />
                      </div>
                    </div>

                    {/* Prompt Template */}
                    <div>
                      <div className="flex items-center justify-between mb-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Prompt Template
                        </label>
                        <button
                          className="text-sm text-purple-600 hover:text-purple-700"
                          title="Learn about prompt variables"
                        >
                          <HelpCircle className="h-4 w-4" />
                        </button>
                      </div>
                      <textarea
                        rows={8}
                        value={templates[selectedTemplate]?.pythonCode || ''}
                        onChange={(e) => {
                          setTemplates(prev => ({
                            ...prev,
                            [selectedTemplate]: {
                              ...prev[selectedTemplate],
                              pythonCode: e.target.value
                            }
                          }));
                        }}
                        className="w-full px-3 py-2 font-mono text-sm border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                        placeholder="Enter the Claude prompt template..."
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        Use {'{content-to-rewrite}'} as a placeholder for the user's input content
                      </p>
                    </div>

                    {/* Additional Settings */}
                    <div className="space-y-4">
                      <div className="flex items-center space-x-4">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={templates[selectedTemplate]?.premium || false}
                            onChange={(e) => {
                              setTemplates(prev => ({
                                ...prev,
                                [selectedTemplate]: {
                                  ...prev[selectedTemplate],
                                  premium: e.target.checked
                                }
                              }));
                            }}
                            className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-sm text-gray-700">Premium Feature</span>
                        </label>
                      </div>

                      <div className="flex items-center space-x-4">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={templates[selectedTemplate]?.active !== false}
                            onChange={(e) => {
                              setTemplates(prev => ({
                                ...prev,
                                [selectedTemplate]: {
                                  ...prev[selectedTemplate],
                                  active: e.target.checked
                                }
                              }));
                            }}
                            className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-sm text-gray-700">Active</span>
                        </label>
                      </div>
                    </div>

                    {/* Template Description */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Template Description
                      </label>
                      <textarea
                        rows={3}
                        value={templates[selectedTemplate]?.description || ''}
                        onChange={(e) => {
                          setTemplates(prev => ({
                            ...prev,
                            [selectedTemplate]: {
                              ...prev[selectedTemplate],
                              description: e.target.value
                            }
                          }));
                        }}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                        placeholder="Enter a description for this template..."
                      />
                    </div>

                    {/* Category Selection */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Category
                      </label>
                      <select
                        value={templates[selectedTemplate]?.category || 'long-form'}
                        onChange={(e) => {
                          setTemplates(prev => ({
                            ...prev,
                            [selectedTemplate]: {
                              ...prev[selectedTemplate],
                              category: e.target.value
                            }
                          }));
                        }}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
                      >
                        {Object.entries(categories).map(([id, category]) => (
                          <option key={id} value={id}>
                            {category.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center py-12">
                  <Settings className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">
                    Select a Template
                  </h3>
                  <p className="text-gray-500">
                    Choose a template from the sidebar to configure its settings
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Content Editor</h2>
            <p className="text-gray-500">Editor functionality coming soon...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;