// Create this file at src/components/AdminPointsManager.js
import React, { useState } from 'react';
import { AlertCircle, CheckCircle2, Coins } from 'lucide-react';

const AdminPointsManager = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setStatus('');

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('http://localhost:5001/api/admin/set-unlimited-points', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to set unlimited points');
      }

      setStatus('Unlimited points granted successfully!');
      setEmail('');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
      <h2 className="text-xl font-bold text-gray-900 mb-6">
        Grant Unlimited Points
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            User Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            placeholder="user@example.com"
            required
          />
        </div>

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
              <span className="text-sm text-red-800">{error}</span>
            </div>
          </div>
        )}

        {status && (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <CheckCircle2 className="h-5 w-5 text-green-400 mr-2" />
              <span className="text-sm text-green-800">{status}</span>
            </div>
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          className={`flex items-center justify-center w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 ${
            loading ? 'opacity-75 cursor-not-allowed' : ''
          }`}
        >
          <Coins className="h-5 w-5 mr-2" />
          {loading ? 'Processing...' : 'Grant Unlimited Points'}
        </button>
      </form>
    </div>
  );
};

export default AdminPointsManager;