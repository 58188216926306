import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  MessageSquare, 
  Instagram, 
  Twitter, 
  Facebook, 
  Linkedin,
  ArrowRight,
  Star,
  ChevronDown,
  Sparkles,
  Target,
  Repeat,
  Calendar,
  Hash,
  TrendingUp,
  Check,
  Clock
} from 'lucide-react';

const SocialMediaWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState('instagram');

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const platforms = {
    instagram: {
      icon: Instagram,
      name: "Instagram",
      examples: [
        "✨ Living my best life one adventure at a time! 🌎 #wanderlust #travel",
        "🔥 Transform your body, transform your life. New fitness program dropping soon! 💪",
        "🌟 The perfect blend of style and comfort. Shop our new collection now! 🛍️"
      ]
    },
    twitter: {
      icon: Twitter,
      name: "Twitter",
      examples: [
        "Breaking: Our latest feature just dropped! Here's what you need to know...",
        "Pro tip: The best time to post on Twitter is when your audience is most active 📊",
        "Hot take: AI is not replacing creativity, it's enhancing it. Here's why..."
      ]
    },
    facebook: {
      icon: Facebook,
      name: "Facebook",
      examples: [
        "🎉 Big announcement! We're excited to share our latest product launch...",
        "Looking for expert tips on digital marketing? Join our free webinar!",
        "Customer spotlight: See how Sarah transformed her business using our platform"
      ]
    },
    linkedin: {
      icon: Linkedin,
      name: "LinkedIn",
      examples: [
        "Excited to announce that our team has reached a major milestone...",
        "Three key trends that are reshaping the future of work...",
        "Leadership lesson: The importance of emotional intelligence in management"
      ]
    }
  };

  const features = [
    {
      title: "Platform-Optimized Content",
      description: "Generate content specifically tailored for each social media platform's unique style and audience",
      icon: Target
    },
    {
      title: "Bulk Creation",
      description: "Create multiple posts at once for your content calendar and schedule them ahead",
      icon: Calendar
    },
    {
      title: "Smart Hashtags",
      description: "Auto-generate relevant, trending hashtags to maximize your post's reach",
      icon: Hash
    },
    {
      title: "Multiple Variations",
      description: "Get multiple variations of each post to test different approaches",
      icon: Repeat
    }
  ];

  const benefits = [
    {
      title: "Save Hours Daily",
      description: "Create a week's worth of social media content in minutes"
    },
    {
      title: "Stay Consistent",
      description: "Maintain a regular posting schedule with fresh content"
    },
    {
      title: "Increase Engagement",
      description: "Generate content that resonates with your audience"
    },
    {
      title: "Track Performance",
      description: "Analyze which content types perform best"
    }
  ];

  const testimonials = [
    {
      name: "Alex Rivera",
      role: "Social Media Manager",
      company: "Digital First",
      content: "This tool has cut my content creation time in half. The platform-specific optimization is incredible!"
    },
    {
      name: "Sophie Chen",
      role: "Influencer",
      company: "100K+ Followers",
      content: "I use this daily for my content planning. The hashtag suggestions are always on point and help boost my reach."
    },
    {
      name: "James Wilson",
      role: "Marketing Director",
      company: "TechStart",
      content: "Managing multiple brand accounts became so much easier. The quality and consistency of content is outstanding."
    }
  ];

  const faqs = [
    {
      question: "Which social media platforms are supported?",
      answer: "Our tool supports all major social media platforms including Instagram, Twitter, Facebook, LinkedIn, TikTok, and Pinterest. Each platform gets its own specialized content optimization."
    },
    {
      question: "Can I customize the tone and style?",
      answer: "Yes! You can choose from multiple tones (professional, casual, friendly, humorous) and styles to match your brand voice. You can also save your preferences for future use."
    },
    {
      question: "How many posts can I generate at once?",
      answer: "You can generate up to 50 posts at once for bulk content creation. This is perfect for planning your content calendar ahead of time."
    },
    {
      question: "Are the hashtag suggestions updated regularly?",
      answer: "Yes, our hashtag suggestions are updated in real-time based on current trends and performance data to ensure maximum reach for your posts."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <h1 className="text-5xl font-bold mb-6">
          <span className="block">Create Engaging Social Media Content</span>
          <span className="block text-purple-600">In Seconds</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
          Generate platform-optimized social media posts that engage your audience and drive results, powered by AI.
        </p>
        <div className="flex justify-center gap-4">
          <Link 
            to="/register"
            className="bg-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-purple-700 transition-all flex items-center"
          >
            Start Creating Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <a 
            href="#demo"
            className="border border-purple-600 text-purple-600 px-8 py-3 rounded-lg font-medium hover:bg-purple-50 transition-all"
          >
            See Examples
          </a>
        </div>

        {/* Platform Icons */}
        <div className="mt-12 flex justify-center space-x-8">
          {Object.entries(platforms).map(([key, platform]) => (
            <button
              key={key}
              onClick={() => setSelectedPlatform(key)}
              className={`p-4 rounded-lg transition-all ${
                selectedPlatform === key 
                  ? 'bg-purple-100 text-purple-600' 
                  : 'text-gray-400 hover:text-gray-600'
              }`}
            >
              <platform.icon className="h-8 w-8" />
            </button>
          ))}
        </div>
      </div>

      {/* Platform Examples */}
<div id="demo" className="max-w-4xl mx-auto px-4 py-16">
  <h2 className="text-3xl font-bold text-center mb-12">
    Content Examples for {platforms[selectedPlatform].name}
  </h2>
  <div className="space-y-4">
    {platforms[selectedPlatform].examples.map((example, index) => {
      const PlatformIcon = platforms[selectedPlatform].icon;
      return (
        <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center mb-4">
            <PlatformIcon className="h-6 w-6 text-purple-600 mr-3" />
            <span className="font-medium">{platforms[selectedPlatform].name} Post</span>
          </div>
          <p className="text-gray-800">{example}</p>
        </div>
      );
    })}
  </div>
</div>

      {/* Features Grid */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Powerful Features</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-xl">
                <feature.icon className="h-12 w-12 text-purple-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Our Tool</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <Check className="h-12 w-12 text-green-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">What Our Users Say</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-xl">
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <div>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Social Media?</h2>
          <p className="text-xl mb-8">Join thousands of creators who trust our AI writer</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-purple-600 font-medium hover:bg-gray-100 transition-all"
          >
            Get Started Free
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaWriterLanding;