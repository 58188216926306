import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  PenTool,
  Target,
  ArrowRight,
  ChevronDown,
  MessageSquare,
  Users,
  Sparkles,
  BarChart,
  Clock,
  Brain,
  Globe,
  Languages,
  Shapes,
  Settings,
  CheckCircle
} from 'lucide-react';

const SalesCopyWriterLanding = () => {
  const [openFaq, setOpenFaq] = useState(null);
  const [selectedExample, setSelectedExample] = useState('ecommerce');

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const copyTypes = [
    {
      title: "Product Descriptions",
      description: "Compelling product narratives that drive sales",
      icon: Shapes
    },
    {
      title: "Email Sequences",
      description: "Converting drip campaigns and newsletters",
      icon: MessageSquare
    },
    {
      title: "Sales Pages",
      description: "High-converting long-form sales content",
      icon: BarChart
    },
    {
      title: "Ad Copy",
      description: "Attention-grabbing ads for all platforms",
      icon: Target
    },
    {
      title: "Social Media",
      description: "Engaging posts that generate leads",
      icon: Globe
    },
    {
      title: "Multi-language",
      description: "Sales copy in 20+ languages",
      icon: Languages
    }
  ];

  const exampleCopy = {
    ecommerce: {
      title: "Premium Leather Wallet",
      before: "Good quality wallet made of leather. Has many pockets and looks nice.",
      after: "Crafted from full-grain Italian leather, this executive wallet combines timeless elegance with modern functionality. With 8 dedicated card slots, a spacious bill compartment, and RFID protection, it's the perfect blend of style and security for the distinguished professional."
    },
    saas: {
      title: "Project Management Software",
      before: "Project management tool that helps teams work better.",
      after: "Transform your team's productivity with our intuitive project management solution. Streamline workflows, automate repetitive tasks, and gain real-time insights into project progress. Perfect for growing teams looking to scale their impact without scaling complexity."
    },
    service: {
      title: "Business Consulting",
      before: "We help businesses grow and improve their operations.",
      after: "Ready to unlock your business's full potential? Our strategic consulting services have helped 500+ companies achieve an average 43% revenue growth within 12 months. We don't just offer advice – we deliver actionable strategies backed by 20 years of industry expertise."
    }
  };

  const benefits = [
    {
      title: "AI-Powered Persuasion",
      description: "Our AI analyzes thousands of high-converting copy examples to create compelling arguments that drive action.",
      icon: Brain
    },
    {
      title: "Industry-Specific",
      description: "Tailored copy that speaks directly to your target market using industry-specific language and pain points.",
      icon: Target
    },
    {
      title: "Instant Generation",
      description: "Generate months worth of sales copy in minutes, not weeks.",
      icon: Clock
    },
    {
      title: "Multi-Channel Ready",
      description: "Automatically adapt your copy for different platforms and formats.",
      icon: Globe
    }
  ];

  const faqs = [
    {
      question: "How does the AI ensure the copy is original?",
      answer: "Our AI generates unique copy for each request by analyzing your specific product, audience, and goals. It doesn't copy existing content but creates original persuasive arguments based on proven sales principles and your input."
    },
    {
      question: "Can I customize the tone and style of the copy?",
      answer: "Yes! You can adjust parameters like tone (professional, casual, luxury), style (direct, storytelling, educational), and brand voice. The AI adapts its output to match your brand's unique personality."
    },
    {
      question: "What languages are supported?",
      answer: "We currently support 20+ languages including English, Spanish, French, German, Italian, Portuguese, Chinese, Japanese, and Korean. The AI maintains persuasive impact across all languages."
    },
    {
      question: "How many versions can I generate?",
      answer: "You can generate unlimited versions of your copy. We recommend creating 3-5 variations to test different approaches and find what resonates best with your audience."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <div className="py-20 text-center px-4">
        <div className="max-w-4xl mx-auto">
          <div className="inline-block mb-6">
            <span className="bg-blue-100 text-blue-800 px-4 py-2 rounded-full text-sm font-medium">
              AI-Powered Sales Copy Generator
            </span>
          </div>
          <h1 className="text-5xl font-bold mb-6">
            <span className="block">Transform Your Words</span>
            <span className="block text-blue-600">Into Sales Machines</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Create persuasive, high-converting sales copy in seconds.
            Let AI craft your message while you focus on growing your business.
          </p>
          <div className="flex justify-center gap-4">
            <Link 
              to="/register"
              className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transition-all flex items-center"
            >
              Start Writing Free
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
            <a 
              href="#examples"
              className="border border-blue-600 text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-all"
            >
              See Examples
            </a>
          </div>
        </div>
      </div>

      {/* Copy Types Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Create Any Type of Sales Copy</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {copyTypes.map((type, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all">
              <type.icon className="h-12 w-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{type.title}</h3>
              <p className="text-gray-600">{type.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Before/After Examples */}
      <div className="bg-white py-16" id="examples">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">See the Difference</h2>
          <div className="flex justify-center mb-8">
            <div className="flex gap-4 bg-gray-100 p-2 rounded-lg">
              {Object.keys(exampleCopy).map((key) => (
                <button
                  key={key}
                  onClick={() => setSelectedExample(key)}
                  className={`px-4 py-2 rounded-lg capitalize ${
                    selectedExample === key 
                      ? 'bg-white shadow-sm text-blue-600' 
                      : 'text-gray-600'
                  }`}
                >
                  {key}
                </button>
              ))}
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="text-lg font-semibold mb-4">Before</h3>
              <p className="text-gray-600">{exampleCopy[selectedExample].before}</p>
            </div>
            <div className="bg-blue-50 p-6 rounded-xl">
              <h3 className="text-lg font-semibold mb-4">After</h3>
              <p className="text-gray-800">{exampleCopy[selectedExample].after}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-16 bg-gradient-to-r from-blue-50 to-indigo-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Why Choose Our AI Copy Creator?</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white rounded-xl p-6 text-center">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <benefit.icon className="h-6 w-6 text-blue-600" />
                </div>
                <h3 className="font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Social Proof */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-12">Trusted by Marketing Teams Worldwide</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              "2M+ Copies Generated",
              "98% Time Saved",
              "145% Conv. Rate Increase",
              "12,000+ Active Users"
            ].map((stat, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-xl">
                <p className="font-semibold">{stat}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Common Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm">
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex justify-between items-center w-full px-6 py-4 text-left"
                >
                  <span className="font-medium text-lg">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="px-6 pb-4 text-gray-600">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Final CTA */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center text-white">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Sales Copy?</h2>
          <p className="text-xl mb-8">Join thousands of businesses using AI to boost their conversions</p>
          <Link
            to="/register"
            className="inline-flex items-center px-8 py-4 rounded-lg bg-white text-blue-600 font-medium hover:bg-gray-100 transition-all"
          >
            Start Writing Now
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
          <p className="mt-4 text-blue-100">No credit card required • Free plan available</p>
        </div>
      </div>
    </div>
  );
};

export default SalesCopyWriterLanding;